import {CELSIUS_UNIT, HZ_UNIT, MSECONDS_UNIT, PERCENT_UNIT, SECONDS_UNIT} from "./decode_bulb_attributes";

function validateDecimalData(value, bytes = 1) {
    if (value.length < 1 || isNaN(value)) {
        return 'value should be numerical'
    }
    if (bytes === 1 && Number(value)>=256) {
        return 'value should be under 256'
    } else if (bytes === 2 && Number(value >= 65536)) {
        return 'value should be under 65536'
    }
    if (Number(value) < 0) {
        return 'value should be positive number'
    }
    return '';
}

function validatePercent(value, bytes = 1) {
    value = value.replace('%', '');
    if (!isNaN(value) && value > 100) {
        return 'value should be less than 100';
    }
    return validateAnnotatedDecimal(value, PERCENT_UNIT, bytes);
}

function validateCelsius(value, bytes = 1) {
    return validateAnnotatedDecimal(value, CELSIUS_UNIT, bytes);
}

function validateSeconds(value, bytes = 1) {
    return validateAnnotatedDecimal(value, SECONDS_UNIT, bytes);
}

function validateMSeconds(value, bytes = 1) {
    return validateAnnotatedDecimal(value, MSECONDS_UNIT, bytes);
}

function validateFrequency(value, bytes = 1) {
    return validateAnnotatedDecimal(value, HZ_UNIT, bytes);
}

function validateAnnotatedDecimal(value, unit, bytes){
    if(value==='n/a'){
        return '';
    }
    let valueWithoutUnit = value.replace(unit, "");
    return validateDecimalData(valueWithoutUnit, bytes);
}

function validateGPIO(value, bytes) {
    if(value.length < 1){
        return '';
    }
    return validateAnnotatedDecimal(value, '', bytes);
}

export {validateDecimalData, validatePercent, validateCelsius, validateSeconds, validateMSeconds, validateFrequency, validateGPIO}
