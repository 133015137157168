import React from "react";

export default function ConfirmationModalComponent({ openModal, deviceName, closeConfimrationModal, pushConfiguration, errorMessage }) {
    return (

        <div id="confirmationModal" class={openModal ? "confirmation-modal open-confirmation-modal " : "confirmation-modal"}>
            <div class="confirmation-modal-content">
                <span class="close-confirmation-modal" onClick={closeConfimrationModal}>&times;</span>
                {
                    errorMessage === '' ?
                        <p>The uploaded JSON file is updating the attributes to <span>{deviceName}</span>. <br /><br />Do you want to Continue ?</p> :
                        <p>{errorMessage}</p>
                }
                <div className="confirmation-action-items">
                    <button className={errorMessage ? "hide" : "confirm"} onClick={pushConfiguration}>Confirm</button>
                    <button className="cancel" onClick={closeConfimrationModal}>Cancel</button>
                </div>
            </div>
        </div>
    )
}
