import {PERCENT_UNIT, CELSIUS_UNIT, SECONDS_UNIT, MSECONDS_UNIT, HZ_UNIT} from "./decode_bulb_attributes";
function encodeDecimalData(value, bytes= 1) {
    let encoded;
    if(value === ''){
        return '';
    }
    if (bytes === 1) {
        encoded = Math.abs(value).toString(16).padStart(2,'0').toUpperCase();
    }else if (bytes === 2){
        encoded = Math.abs(value).toString(16).padStart(4,'0').toUpperCase();
        encoded = encoded.substring(2).concat(encoded.substring(0,2))
    }
    return encoded;
}

function encodeGPIO(value, bytes = 1) {
    if (value === 'n/a') {
        if(bytes === 2){
            return 'FFFF';
        }
        return 'FF';
    }
    return encodeDecimalData(value, bytes);
}

function encodeAnnotatedUnit(value, bytes = 1){
    const regex = new RegExp(`${PERCENT_UNIT}|${CELSIUS_UNIT}|${SECONDS_UNIT}|${MSECONDS_UNIT}|${HZ_UNIT}`,'g')
    let encoded = value.replace(regex, "");
    encoded = encodeGPIO(encoded, bytes);
    return encoded;
}

function encodeLimitedDecimal(value, bytes = 1, options) {
    let selectedOption = options.find(option => option.key === value);
    let decodedValue;
    if (selectedOption) {
        decodedValue = selectedOption.value;
    } else if(value.includes('UNEXPECTED VALUE: ')) {
        decodedValue = value.substring('UNEXPECTED VALUE: '.length);
    } else {
        decodedValue = value;
    }
    return encodeGPIO(decodedValue, bytes);
}

function encodeFlags(value, bytes = 1) {
    let encoded = encodeFlagsToBits(value);
    encoded = parseInt(encoded, 2);
    encoded = Math.abs(encoded).toString(16).padStart(2,'0').toUpperCase();
    return encoded;
}

function encodeFlagsToBits(value) {
    let encodedValue = '';
    value.forEach(element => {
        let elementBit = element.bit;
        encodedValue = elementBit.concat(encodedValue);
    })
    return encodedValue;
}

function encodeColor(color) {
    let encoded = '' + encodeDecimalData(color.version);
    color.colorSets.forEach(set => {
        encoded += encodeColorSet(set);
    })
    return encoded;
}

function encodeColorSet(colorRow) {
    let defaults = [colorRow.definition.red, colorRow.definition.green, colorRow.definition.blue];
    // Start by adding the input values to the nascent attribute
    let colorDefinition = hexifyTriplet(defaults);
    colorRow.hex = colorDefinition;
    let tuningFactors = [colorRow.tuning.red, colorRow.tuning.green, colorRow.tuning.blue];
    // Do the math, applying tuning factor and scaling the color components for this row
    let tunedValues = tuneTriplet(tuningFactors);
    // Finally, add the tuned values to the nascent attribute
    colorDefinition += hexifyTriplet(tunedValues);
    return colorDefinition;
}

function hexifyTriplet(values) {
    // Accept triplet array of decimal percent values, convert to 8-bit hex, bundle together
    let outVal = "";
    values.forEach((element) => {
        outVal += (element).toString(16).toUpperCase().padStart(2, '0');
    });
    return outVal;
}

function tuneTriplet(tuningValues) {
    // Tune the color components according to "Duty Ratios", then scale so max = 100%
    let tunedValues = [];
    let scaledValues = [];
    tuningValues.forEach((tuningVal, idx) => {
        tunedValues.push(tuningVal / 255);
    });
    const scaleFactor = 1 / (Math.max(tunedValues[0], tunedValues[1], tunedValues[2]) || 1);
    tunedValues.forEach((tunedVal) => {
        scaledValues.push(Math.round(tunedVal * scaleFactor * 255));
    });
    return scaledValues;
}

function encodeRGB(rgb) {
    if(rgb.applicable === false) {
        return 'FF';
    }
    let redBinary = parseInt(rgb.red).toString(2).padStart(2,'0');
    let greenBinary = parseInt(rgb.green).toString(2).padStart(2,'0');
    let blueBinary = parseInt(rgb.blue).toString(2).padStart(2,'0');
    let binary = redBinary.concat(greenBinary).concat(blueBinary);
    return parseInt(binary, 2).toString(16).toUpperCase();
}

export {encodeDecimalData, encodeGPIO, encodeAnnotatedUnit, encodeLimitedDecimal, encodeFlags,
    encodeColorSet, encodeColor, encodeRGB}
