import { AFERO_DATATYPES } from '../../util/constants';
import {getDeviceProfile} from "../../api/http_request_wrapper";

/*this middleware parses for device attributes based on
deviceId selected by user on either HomeComponent or MainComponent*/

export function parseDataForDeviceDetailsByAccountId() {
  let devs = JSON.parse(localStorage.getItem('accountDevices'));
  let {deviceId} = JSON.parse(localStorage.getItem('selectedDeviceObj'));
  let devFiltered = devs.filter(dev => dev.deviceId === deviceId);
  let curAttrValue = {};

  let devDetails = devFiltered.map(prop => {
    let obj = {};

    obj.accountId = prop.accountId;
    obj.friendlyName = !prop.friendlyName ? 'UNKNOWN' : prop.friendlyName;
    obj.available = prop.deviceState.available;
    obj.deviceId = prop.deviceId;
    obj.profileId = prop.profileId;
    obj.partnerId = prop.partnerId;
    obj.deviceTypeId = prop.deviceTypeId;
    obj.latitude = prop.deviceState.hasOwnProperty('location') ? prop.deviceState.location.latitude : null;
    obj.longitude = prop.deviceState.hasOwnProperty('location') ? prop.deviceState.location.longitude : null;
    obj.updatedTimestamp = prop.deviceState.hasOwnProperty('location') ? prop.deviceState.location.lastUpdatedTimestamp : null;

    curAttrValue[obj.deviceId] = {};
    prop.attributes.forEach(attr => {
      curAttrValue[obj.deviceId][attr.id] = {
        value: attr.value,
        updatedTimestamp: attr.updatedTimestamp
      };
    });
    return obj;
  });
  return {curAttrValue, devDetails};
}

export default function getDevAttributesProcess(router, deviceProfile) {
  let devicesScope = {};

  return async (dispatch, getState) => {
    try {
      let {curAttrValue, devDetails} = parseDataForDeviceDetailsByAccountId();

      /* add devDetails as property to devicesScope */
      devicesScope.devDetails = devDetails;

      /* parsing data for Devices attributes by accountId & deviceId */
      let devProfile = deviceProfile;
      if (!deviceProfile){
        devProfile = await getDeviceProfile(devDetails[0].accountId, devDetails[0].deviceId)
      }
      let devAttrParsed = devProfile.services.map(prop => {
        return prop.attributes.map(attrVal => {
          let obj = {};

          obj.available = devDetails[0].available;
          obj.attributeId = attrVal.id;
          obj.value = curAttrValue[devDetails[0].deviceId][attrVal.id].value;
          obj.dataType = attrVal.dataType;
          obj.length = attrVal.length;
          obj.updatedTimestamp = curAttrValue[devDetails[0].deviceId][attrVal.id].updatedTimestamp;
          obj.semanticType = attrVal.semanticType;
          obj.updatedErrorMsg = '';
          obj.operations = attrVal.operations;
          return obj;
        });
      });
      devDetails[0].attributes = devAttrParsed;

      /*under Device's attribute, attributeId should be filtered, see constants.js for details*/
      devicesScope.devDetails = devicesScope.devDetails.map(devProp => {
        let attrFiltered = devProp.attributes[0].filter(prop => {
          return (
            (prop.attributeId >= AFERO_DATATYPES.MCU.min && prop.attributeId <= AFERO_DATATYPES.MCU.max) ||
            (prop.attributeId >= AFERO_DATATYPES.GPIO.min && prop.attributeId <= AFERO_DATATYPES.GPIO.max) ||
            (prop.attributeId >= AFERO_DATATYPES.APP.min && prop.attributeId <= AFERO_DATATYPES.APP.max)
          );
        });
        return {
          accountId: devProp.accountId,
          friendlyName: devProp.friendlyName,
          available: devProp.available,
          deviceId: devProp.deviceId,
          profileId: devProp.profileId,
          partnerId: devProp.partnerId,
          deviceTypeId: devProp.deviceTypeId,
          latitude: devProp.latitude,
          longitude: devProp.longitude,
          updatedTimestamp: devProp.updatedTimestamp,
          attributes: attrFiltered
        };
      });
      dispatch({ type: 'GET_DEVICE_ATTRIBUTES', deviceAttribute: devicesScope.devDetails[0], getDeviceAttributesFlag: true });
    } catch (error) {
      console.log(error);
    }
  };
}
