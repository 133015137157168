import React from 'react';
import { Button, Icon, Table, Comment, Input } from 'semantic-ui-react';
import ReactTooltip from 'react-tooltip';

export default function VisualComponent({
  connectionLogs,
  currentConnection,
  displayCurrentConnectionFlag,
  connectionLogsClassNameContainer,
  handle_downloadConnectionLogs,
  handle_toggleConnectionLogs,
  deviceLogs,
  handle_clearDevLogs,
  handle_downloadDevLogs,
  searchText, onChange_searchText
}) {
  let deviceObj = localStorage.getItem('selectedDeviceObj') ? JSON.parse(localStorage.getItem('selectedDeviceObj')) : null;
  let curBluetoothSignal = connectionLogs[deviceObj.deviceId].connections.bluetoothSignal;
  let curWifiSignal = connectionLogs[deviceObj.deviceId].connections.wifiSignal;

  return (
    <div className={connectionLogsClassNameContainer}>
      {currentConnection === 'bluetooth' ? (
        <div id='bluetooth_item'>
          <strong id='bluetoothTitle'>Bluetooth Connection</strong>
          <div className='tooltip_item'>
            <ReactTooltip type='light' effect='solid' place='top' />
            <span data-tip='Download RSSI log contents'>
              <Button circular size='mini' icon='download' data-for='rssiCopyTip' onClick={handle_downloadConnectionLogs} />
            </span>
          </div>
          <div className='tooltip_item_right'>
            <ReactTooltip type='light' effect='solid' place='top' />
            <span data-tip={displayCurrentConnectionFlag ? 'Hide RSSI data' : 'Show RSSI data'}>
              <Icon
                bordered
                style={{ color: 'white' }}
                size='small'
                name='bars'
                id='openCloseConnectionLogs'
                data-for='toggleRssi'
                onClick={handle_toggleConnectionLogs}
              />
            </span>
          </div>
          <br />
          Device signal strength as measured at hubs
          {displayCurrentConnectionFlag ? (
            <div>
              {curBluetoothSignal === null ? (
                <Table unstackable singleLine id='bluetoothTable'>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell collapsing>RSSI</Table.Cell>
                      <Table.Cell>{'NULL'}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell collapsing>Timestamp</Table.Cell>
                      <Table.Cell>{'NULL'}</Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              ) : (
                <Table unstackable singleLine id='bluetoothTable'>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell collapsing>RSSI</Table.Cell>
                      <Table.Cell>{curBluetoothSignal.signalStrength}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell collapsing>Timestamp</Table.Cell>
                      <Table.Cell>{curBluetoothSignal.timestamp}</Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              )}
            </div>
          ) : (
            ''
          )}
        </div>
      ) : (
        <div id='wifi_item'>
          <strong id='wifiTitle'>Wi-Fi Connection</strong>
          <div className='tooltip_item'>
            <ReactTooltip type='light' effect='solid' place='top' />
            <span data-tip='Download RSSI log contents'>
              <Button circular size='mini' icon='download' data-for='rssiCopyTip' onClick={handle_downloadConnectionLogs} />
            </span>
          </div>
          <div className='tooltip_item_right'>
            <ReactTooltip type='light' effect='solid' place='top' />
            <span data-tip={displayCurrentConnectionFlag ? 'Hide RSSI data' : 'Show RSSI data'}>
              <Icon
                bordered
                style={{ color: 'white' }}
                size='small'
                name='bars'
                id='openCloseConnectionLogs'
                data-for='toggleRssi'
                onClick={handle_toggleConnectionLogs}
              />
            </span>
          </div>
          <br />
          Device signal strength
          {displayCurrentConnectionFlag ? (
            <div>
              {curWifiSignal === null ? (
                <Table unstackable singleLine id='wifiTable'>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell collapsing>SSID</Table.Cell>
                      <Table.Cell>{'Null'}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell collapsing>Wi-Fi Bars</Table.Cell>
                      <Table.Cell>{'Null'}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell collapsing>Steady State</Table.Cell>
                      <Table.Cell>{'Null'}</Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              ) : (
                <Table unstackable singleLine id='wifiTable'>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell collapsing>SSID</Table.Cell>
                      <Table.Cell>{curWifiSignal.ssId}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell collapsing>Wi-Fi Bars</Table.Cell>
                      <Table.Cell>{curWifiSignal.wifiBar}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell collapsing>Steady State</Table.Cell>
                      <Table.Cell>{curWifiSignal.steadyState}</Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              )}
            </div>
          ) : (
            ''
          )}
        </div>
      )}

      <div id='deviceLog_item'>
        <Comment.Group style={{display: 'flex', flexDirection:'column', width:'100%'}} >
          <div>
            <strong id='devLogsTitle'>Device Log</strong>
            <div className='tooltip_item'>
              <ReactTooltip type='light' effect='solid' place='top' />
              <span data-tip='Download log contents'>
                <Button
                  circular
                  size='mini'
                  icon='download'
                  data-for='downloadLogTip'
                  disabled={!(deviceLogs[deviceObj.deviceId] && deviceLogs[deviceObj.deviceId].logs.length > 0)}
                  onClick={handle_downloadDevLogs}
                />
              </span>
            </div>

            <div className='tooltip_item'>
              <ReactTooltip type='light' effect='solid' place='top' />
              <span data-tip='Clear log contents'>
                <Button
                  circular
                  size='mini'
                  icon='x'
                  data-for='clearLogContentsTip'
                  data-tip-disable={deviceLogs[deviceObj.deviceId].logs.length <= 0}
                  disabled={!(deviceLogs[deviceObj.deviceId] && deviceLogs[deviceObj.deviceId].logs.length > 0)}
                  onClick={handle_clearDevLogs}
                />
              </span>
            </div>
            <br className='clear' />
            <Input
              fluid
              id='filterInput'
              type='textarea'
              icon='search'
              placeholder='Type search text'
              autoFocus={true}
              value={searchText}
              onChange={onChange_searchText}
            />
          </div>
          <Comment className={'devLogs'}>
            {searchText === ''?
              deviceLogs[deviceObj.deviceId]?.logs.map((str, i) => {
                return (
                    <p key={i} id='devLogsUpdateText'>
                      {str}
                    </p>
                );
              })
              : deviceLogs[deviceObj.deviceId].logs.map((str, i) => {
                let expression = new RegExp(searchText, 'g');
                let matched = str.toLowerCase().match(expression);
                let devLogsFiltered = [];

                if (matched) {
                  devLogsFiltered.push(
                    <p id='devLogsUpdateText' key={i}>
                      {str}
                    </p>
                  );
                }
                return devLogsFiltered;
              })
            }
          </Comment>
        </Comment.Group>
      </div>
    </div>
  );
}
