import { useParams } from 'react-router-dom';
import MainContainer from '../../redux/containers/MainContainer';
import SigninContainer from '../../redux/containers/SigninContainer';
import Error404Component from "../miscellaneous/Error404Component";
//returns the main container after checking the access. If no access to a device then redirect to error component.
export default function MainRouteComponent() {
  let params = useParams();
  const paramDeviceId = params.deviceId;
  let allUserDevices = localStorage.getItem('allUserDevs') ? JSON.parse(localStorage.getItem('allUserDevs')) : false;
  let haveSelectedDeviceObj = localStorage.getItem('selectedDeviceObj') ? true : false;
  let haveAccessToDevice = allUserDevices && allUserDevices.includes(paramDeviceId);

  if (!haveSelectedDeviceObj) {
    return <SigninContainer />;
  }

  if (!haveAccessToDevice) {
    return <Error404Component />;
  }

  return <MainContainer />;

}
