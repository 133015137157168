import * as auth from '../../api/authentication';
import { AFERO_DATATYPES, ProfileValidator, PRIVATE_COMMUNICATION_CHANNEL, PRIVATE_SYSTEM } from '../../util/constants';
import {updateDeviceActions} from "../../api/http_request_wrapper";

/*allows users to edit attributes within the MainComponent whenever their Operation is under Write/Read status*/

export default function updateAttributesProcess(payload, inputObj, router) {
  function successfulDeviceAction(response, getState, dispatch) {
    updateDeviceAttributeUi(inputObj.attrtype, payload.attrId, response.value)(dispatch, getState);

    if (inputObj.rebootDevice) {
      const rebootInputObj = {
        dataType: 'BYTES',
        attrtype: 'aferoAttribute',
        length: '64',
        userInputValue: '01'
      };
      const payload = {
        type: 'attribute_write',
        attrId: Number('65012'),
        value: '01'
      };

      updateAttributesProcess(payload, rebootInputObj, router)(dispatch, getState);
    }
  }

  return async (dispatch, getState) => {
    await auth.handleAuthentication(router, dispatch);

    const accountId = localStorage.getItem('accountId');
    let { deviceId } = JSON.parse(localStorage.getItem('selectedDeviceObj'));
    let ret = true;

    /*validate user's input under Device Attribute*/
    if (
      (payload.attrId >= AFERO_DATATYPES.MCU.min && payload.attrId <= AFERO_DATATYPES.MCU.max) ||
      (payload.attrId >= AFERO_DATATYPES.GPIO.min && payload.attrId <= AFERO_DATATYPES.GPIO.max) ||
      (payload.attrId >= AFERO_DATATYPES.APP.min && payload.attrId <= AFERO_DATATYPES.APP.max)
    ) {
      let devAttrUpdated = Object.assign({}, getState().deviceAttribute);
      let valRes = ProfileValidator.DATATYPE_VALIDATION_RESULT.VALID;

      if (
        inputObj.dataType === ProfileValidator.ATTRIBUTE_DATA_TYPES.UTF8S.name ||
        inputObj.dataType === ProfileValidator.ATTRIBUTE_DATA_TYPES.BYTES.name
      ) {
        //when it's a string or byte array you can only validate the length really, but not the semantic value
        valRes = ProfileValidator.validateDataType(inputObj.userInputValue, inputObj.dataType, 0, inputObj.length, false);
      } else {
        //validate semantic value
        valRes = ProfileValidator.validateDataType(inputObj.userInputValue, inputObj.dataType);
      }

      if (valRes === ProfileValidator.DATATYPE_VALIDATION_RESULT.NaN) {
        ret = false;

        devAttrUpdated.attributes.some(obj => {
          return obj.attributeId === payload.attrId ? (obj.updatedErrorMsg = 'Please type a value that is a number.') : '';
        });
        dispatch({ type: 'UPDATE_DEVICE_ATTRIBUTES', deviceAttribute: devAttrUpdated });
        dispatch({ type: 'FLAG_SUCCESSFULLY_UPDATE_DEVICE_ATTRIBUTE', updateDeviceAttributeFlag: false });
      } else if (valRes === ProfileValidator.DATATYPE_VALIDATION_RESULT.OUT_OF_BOUNDS) {
        ret = false;
        let min = ProfileValidator.ATTRIBUTE_DATA_TYPES[inputObj.dataType].min;
        let max = ProfileValidator.ATTRIBUTE_DATA_TYPES[inputObj.dataType].max;

        devAttrUpdated.attributes.some(obj => {
          return obj.attributeId === payload.attrId
            ? (obj.updatedErrorMsg = `The value you typed is out of bounds: ${min} -- ${max}.`)
            : '';
        });
        dispatch({ type: 'UPDATE_DEVICE_ATTRIBUTES', deviceAttribute: devAttrUpdated });
        dispatch({ type: 'FLAG_SUCCESSFULLY_UPDATE_DEVICE_ATTRIBUTE', updateDeviceAttributeFlag: false });

        if (
          inputObj.dataType === ProfileValidator.ATTRIBUTE_DATA_TYPES.UTF8S.name ||
          inputObj.dataType === ProfileValidator.ATTRIBUTE_DATA_TYPES.BYTES.name
        ) {
          let max = ProfileValidator.ATTRIBUTE_DATA_TYPES[inputObj.dataType].max;

          devAttrUpdated.attributes.some(obj => {
            return obj.attributeId === payload.attrId
              ? (obj.updatedErrorMsg = `The size of the default value you typed must not be greater than ${max}.`)
              : '';
          });
          dispatch({ type: 'UPDATE_DEVICE_ATTRIBUTES', deviceAttribute: devAttrUpdated });
          dispatch({ type: 'FLAG_SUCCESSFULLY_UPDATE_DEVICE_ATTRIBUTE', updateDeviceAttributeFlag: false });
        }
      } else if (valRes === ProfileValidator.DATATYPE_VALIDATION_RESULT.UNASSIGNABLE) {
        ret = false;

        devAttrUpdated.attributes.some(obj => {
          return obj.attributeId === payload.attrId
            ? (obj.updatedErrorMsg = `The value you typed is not a valid ${inputObj.dataType}.`)
            : '';
        });
        dispatch({ type: 'UPDATE_DEVICE_ATTRIBUTES', deviceAttribute: devAttrUpdated });
        dispatch({ type: 'FLAG_SUCCESSFULLY_UPDATE_DEVICE_ATTRIBUTE', updateDeviceAttributeFlag: false });
      } else if (valRes === ProfileValidator.DATATYPE_VALIDATION_RESULT.BYTES_ODD_LENGTH) {
        ret = false;

        devAttrUpdated.attributes.some(obj => {
          return obj.attributeId === payload.attrId
            ? (obj.updatedErrorMsg =
                'The value you typed has an odd number of characters. Two characters are required to represent one byte.')
            : '';
        });
        dispatch({ type: 'UPDATE_DEVICE_ATTRIBUTES', deviceAttribute: devAttrUpdated });
        dispatch({ type: 'FLAG_SUCCESSFULLY_UPDATE_DEVICE_ATTRIBUTE', updateDeviceAttributeFlag: false });
      }
    }

    /*validate user's input under Afero Attribute*/
    if (
      (payload.attrId >= AFERO_DATATYPES.MCU_VERSION.min && payload.attrId <= AFERO_DATATYPES.MCU_VERSION.max) ||
      (payload.attrId >= AFERO_DATATYPES.VERSION.min && payload.attrId <= AFERO_DATATYPES.VERSION.max) ||
      (payload.attrId >= AFERO_DATATYPES.COMMUNICATION_CHANNEL.min &&
        payload.attrId <= AFERO_DATATYPES.COMMUNICATION_CHANNEL.max &&
        !PRIVATE_COMMUNICATION_CHANNEL.includes(payload.attrId)) ||
      (payload.attrId >= AFERO_DATATYPES.SYSTEM.min &&
        payload.attrId <= AFERO_DATATYPES.SYSTEM.max &&
        !PRIVATE_SYSTEM.includes(payload.attrId))
    ) {
      let currAferoAttr = getState().aferoAttribute;
      let valRes = ProfileValidator.DATATYPE_VALIDATION_RESULT.VALID;

      if (
        inputObj.dataType === ProfileValidator.ATTRIBUTE_DATA_TYPES.UTF8S.name ||
        inputObj.dataType === ProfileValidator.ATTRIBUTE_DATA_TYPES.BYTES.name
      ) {
        valRes = ProfileValidator.validateDataType(inputObj.userInputValue, inputObj.dataType, 0, inputObj.length, false);
      } else {
        valRes = ProfileValidator.validateDataType(inputObj.userInputValue, inputObj.dataType);
      }

      if (valRes === ProfileValidator.DATATYPE_VALIDATION_RESULT.NaN) {
        ret = false;

        currAferoAttr.attributes.some(obj => {
          return obj.attributeId === payload.attrId ? (obj.updatedErrorMsg = 'Please type a value that is a number.') : '';
        });

        dispatch({ type: 'UPDATE_AFERO_ATTRIBUTES', aferoAttribute: currAferoAttr });
        dispatch({ type: 'FLAG_SUCCESSFULLY_UPDATE_DEVICE_ATTRIBUTE', updateDeviceAttributeFlag: false });
      } else if (valRes === ProfileValidator.DATATYPE_VALIDATION_RESULT.OUT_OF_BOUNDS) {
        ret = false;
        let min = ProfileValidator.ATTRIBUTE_DATA_TYPES[inputObj.dataType].min;
        let max = ProfileValidator.ATTRIBUTE_DATA_TYPES[inputObj.dataType].max;

        currAferoAttr.attributes.some(obj => {
          return obj.attributeId === payload.attrId
            ? (obj.updatedErrorMsg = `The value you typed is out of bounds: ${min} -- ${max}.`)
            : '';
        });
        dispatch({ type: 'UPDATE_AFERO_ATTRIBUTES', aferoAttribute: currAferoAttr });
        dispatch({ type: 'FLAG_SUCCESSFULLY_UPDATE_DEVICE_ATTRIBUTE', updateDeviceAttributeFlag: false });

        if (
          inputObj.dataType === ProfileValidator.ATTRIBUTE_DATA_TYPES.UTF8S.name ||
          inputObj.dataType === ProfileValidator.ATTRIBUTE_DATA_TYPES.BYTES.name
        ) {
          let max = ProfileValidator.ATTRIBUTE_DATA_TYPES[inputObj.dataType].max;

          currAferoAttr.attributes.some(obj => {
            return obj.attributeId === payload.attrId
              ? (obj.updatedErrorMsg = `The size of the default value you typed must not be greater than ${max}.`)
              : '';
          });

          dispatch({ type: 'UPDATE_AFERO_ATTRIBUTES', aferoAttribute: currAferoAttr });
          dispatch({ type: 'FLAG_SUCCESSFULLY_UPDATE_DEVICE_ATTRIBUTE', updateDeviceAttributeFlag: false });
        }
      } else if (valRes === ProfileValidator.DATATYPE_VALIDATION_RESULT.UNASSIGNABLE) {
        ret = false;

        currAferoAttr.attributes.some(obj => {
          return obj.attributeId === payload.attrId
            ? (obj.updatedErrorMsg = `The value you typed is not a valid  ${inputObj.dataType}.`)
            : '';
        });

        dispatch({ type: 'UPDATE_AFERO_ATTRIBUTES', aferoAttribute: currAferoAttr });
      } else if (valRes === ProfileValidator.DATATYPE_VALIDATION_RESULT.BYTES_ODD_LENGTH) {
        ret = false;

        currAferoAttr.attributes.some(obj => {
          return obj.attributeId === payload.attrId
            ? (obj.updatedErrorMsg =
                'The value you typed has an odd number of characters. Two characters are required to represent one byte.')
            : '';
        });

        dispatch({ type: 'UPDATE_AFERO_ATTRIBUTES', aferoAttribute: currAferoAttr });
        dispatch({ type: 'FLAG_SUCCESSFULLY_UPDATE_DEVICE_ATTRIBUTE', updateDeviceAttributeFlag: false });
      }
    }

    /*If and only if ret is equal to "true" after validation is done from above, then update the payload to createDeviceActionPromise*/
    if (ret === true) {
      return await updateDeviceActions(accountId, deviceId, payload)
          .then((response) => {
            successfulDeviceAction(response, getState, dispatch)
          })
          .catch(error => {
            console.log('errorCode: ', error)
          });
    }
  };
}

export function updateDeviceAttributeUi(attrType, attrId, updatedValue) {
  return async (dispatch, getState) => {
    if (attrType === 'deviceAttribute') {
      let attribute = getState().deviceAttribute.attributes.find(attr => attr.attributeId === attrId)
      if(!attribute) return
      attribute.value = updatedValue;
      let devAttrUpdated = getState().deviceAttribute;
      devAttrUpdated.attributes.some(obj => {
        return obj.attributeId === Number(attrId) ? (obj.updatedErrorMsg = '') : '';
      });

      dispatch({type: 'UPDATE_DEVICE_ATTRIBUTES', deviceAttribute: devAttrUpdated});
      dispatch({type: 'FLAG_SUCCESSFULLY_UPDATE_DEVICE_ATTRIBUTE', updateDeviceAttributeFlag: true});
    } else if (attrType === 'aferoAttribute') {
      let attribute = getState().aferoAttribute.attributes.find(attr => attr.attributeId === attrId)
      if(!attribute) return
      attribute.value = updatedValue;
      let curAferoAttribute = getState().aferoAttribute;

      curAferoAttribute.attributes.some(obj => {
        return obj.attributeId === Number(attrId) ? (obj.updatedErrorMsg = '') : '';
      });

      dispatch({type: 'UPDATE_AFERO_ATTRIBUTES', aferoAttribute: curAferoAttribute});
      dispatch({type: 'FLAG_SUCCESSFULLY_UPDATE_AFERO_ATTRIBUTE', updateAferoAttributeFlag: true});
    }
  }
}

export function updateFTMDeviceAttribute(attrType, accountId, deviceId) {

  return async (dispatch, getState) => {
    const attrId = 65012;
    const updatedValue= '10';
    if (attrType === 'aferoAttribute') {
      const payload = {
        type: 'attribute_write',
        attrId: Number(attrId),
        value: updatedValue
      };
      await updateDeviceActions(accountId,deviceId,payload);
    }
    
  }
}