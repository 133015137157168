import React from 'react';
import HomeComponent from './HomeComponent';

export default function HomePage({
  clear_rootReducer,
  deviceAttribute,
  flag_displayCurrentConnection,
  getUserStateFlag,
  router,
  connectionLogs,
  userState
}) {
  return (
    <div>
      <HomeComponent
        clear_rootReducer={clear_rootReducer}
        deviceAttribute={deviceAttribute}
        flag_displayCurrentConnection={flag_displayCurrentConnection}
        getUserStateFlag={getUserStateFlag}
        router={router}
        connectionLogs={connectionLogs}
        userState={userState}
      />
    </div>
  );
}
