/*global WEBAPP_CONFIG*/
/*eslint no-undef: ["error", { "typeof": true }] */
import { env } from './client-env'

export default (() => {
  const defaultConfig = {
    production: false,
    cloudEnv: "dev",
    clientId: "inspector",
    clientSecret: "uq7jqqkt",
    cl_kcd_auth: "https://www.stag.myassurelink.com",
    cl_kcd_exch: "https://kenmoreauth.dev.afero.io",
    url_legal: "https://www.dev.afero.io/legal#general",
    url_privacy: "https://www.dev.afero.io/legal#privacy",
    clientApiUrl: env.REACT_APP_CLIENT_API_URL
  };
  const runtimeConf = typeof WEBAPP_CONFIG === "undefined" ? {} : WEBAPP_CONFIG;

  const ret = Object.assign(defaultConfig, runtimeConf);

  return ret;
})();