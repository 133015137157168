import {getDeviceProfile} from "../../api/http_request_wrapper";

/*allow user to download device's full profile on InforDeviceComponent*/

export default function getDevProfileProcess(router) {
  return async (dispatch, getState) => {
    try {
      let { deviceId } = JSON.parse(localStorage.getItem('selectedDeviceObj'));
      let accountId = localStorage.getItem('accountId');
      let devProfile = await getDeviceProfile(accountId, deviceId);

      dispatch({ type: 'GET_DEVICE_PROFILE', deviceProfile: devProfile });
      return devProfile;
    } catch (error) {
      console.log(error);
    }
  };
}
