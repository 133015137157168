import React, {Component} from 'react';
import {Button, ButtonGroup, Confirm, Dropdown, FormCheckbox} from "semantic-ui-react";
export default class ClearSubmitComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submit: this.props.submit,
            submitWithoutValue: this.props.submitWithoutValue,
            confirmClear: false,
            rebootOnSubmit: false
        };
        this.submitThroughDropdown = this.submitThroughDropdown.bind(this);
    }

    render() {
        return (
            <div className='backNext' style={this.props.style}>
                <Button size='huge' id='clearButton'
                        type={'button'}
                        onClick={(event) => this.setState({confirmClear: event})}
                        data-attrid={this.props.parentProps.attrid}
                        data-attrtype={this.props.parentProps.attrtype}
                        data-datatype={'null'}
                > Clear </Button>
                <Confirm
                    open={!!(this.state.confirmClear)}
                    content='Are you sure you want to clear this setting?'
                    onCancel={() => this.setState({confirmClear: false})}
                    onConfirm={() => this.clearConfirmed(this.state.confirmClear)}
                    size='mini'
                    cancelButton={'No'}
                    confirmButton={'Yes'}
                />
                <FormCheckbox checked={this.state.rebootOnSubmit} id={'rebootCheckbox'} style={{display: 'none'}}/>
                <ButtonGroup color={"orange"}>
                    <Button size='huge' id='encodeButton' className={'submitButton tooltip'}
                            disabled={(this.props.warnings === true || this.props.warnings.length > 0)}
                            onClick={(event) => {event.target.rebootDevice = this.state.rebootOnSubmit; this.state.submit(event);}}
                            data-attrid={this.props.parentProps.attrid}
                            data-attrtype={this.props.parentProps.attrtype}
                            data-datatype={this.props.parentProps.datatype}
                            data-datalength={this.props.parentProps.datalength}
                            data-reboot={this.state.rebootDevice}>
                                <span className="tooltiptext" style={{fontSize: "small"}}>
                                    Changes will be applied on reboot.
                                </span>
                        Submit </Button>
                    <Dropdown
                        className='button icon noTextMargin'
                        style={{margin: '0px', padding: '10px'}}
                        floating
                        options={[
                            {key: 'submit', text: 'Submit', value: false, onClick: this.submitThroughDropdown},
                            {key: 'reboot', text: 'Submit & Reboot', value: true, onClick: this.submitThroughDropdown},
                        ]}
                        disabled={(this.props.warnings === true || this.props.warnings.length > 0)}
                        trigger={<></>}
                    />
                </ButtonGroup>
            </div>
        )
    }

    clearConfirmed(event) {
        event.target.userInput = {value: null};
        event.currentTarget = event.target;
        this.setState({confirmClear: false}, ()=> this.props.submitWithoutValue(event))
    }

    submitThroughDropdown(event, {value}) {
        let submitBtn = event.target.parentElement.parentElement.parentElement.parentElement.querySelector('.submitButton');
        event.test='hi';
        this.setState({rebootOnSubmit: value}, ()=> submitBtn.click())
    }

}
