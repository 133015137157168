import React, { Component } from 'react';
import { Form } from 'semantic-ui-react';
import aferoLogo from '../../images/afero-inspector-logo.svg';
import { ReactKeycloakProvider } from "@react-keycloak/web";

import FooterComponent from '../main/FooterComponent';
import KeycloakButton from "../KeycloakButton";
import keycloak from "../../keycloak";

export default class SigninComponent extends Component {
  constructor(props) {
    super(props);

    const urlParams = new URLSearchParams(this.props.router.location.search);
    const handler = urlParams.has('cl') ? urlParams.get('cl') : 'default';

    this.state = {
      signupName: '',
      signupPassword: '',
      handler: handler
    };
  }

  handle_submit = (authenticated, error) => {
    let { router, on_signin } = this.props;
    let { handler } = this.state;
    if (authenticated) {
      on_signin({
        event: authenticated,
        keycloak: keycloak,
        error: error,
        router: router,
        handler: handler
      });
    }
  };

  render() {
    let { signinErrorMsg } = this.props;
    let token = localStorage.getItem('token');
    let refreshToken = localStorage.getItem('refreshToken');
    return (
      <div className='signin-container'>
        <div id='signin-item'>
          <ReactKeycloakProvider
              authClient={keycloak}
              onEvent={this.handle_submit}
              initOptions={{ token, refreshToken }}
          >
            <Form id='signinForm' size='large'>
              <div id='signinLogo'>
                <img src={aferoLogo} alt='Afero, Inc.' />
              </div>
              <p id='signinError'>{signinErrorMsg === '' ? <br /> : signinErrorMsg}</p>
              <KeycloakButton/>
            </Form>
          </ReactKeycloakProvider>
        </div>
        <div className='loginFooter-item'>
          <FooterComponent />
        </div>
      </div>
    );
  }
}
