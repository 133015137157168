import React, {Component} from 'react';
import {
    Header,
    Icon,
    Modal, Popup
} from 'semantic-ui-react';
import ColorDecoderComponent from "./ColorDecoderComponent";
import CCTableDecoderComponent from "./CCTableDecoderComponent";
import Config500Wizard from "./Config500Wizard";
import {isSparkleLightDevice} from "../filters/FilterComponent";
import {attributeValidationMap} from "../../../util/validate_attributes";

export default class DecoderModalComponent extends Component{
    constructor(props) {
        super(props);
        this.state = {
            props: props,
            configValue: props.configAttribute,
            warnings:'',
            configAttributeArray: '',
            editingCells: [],
            infoText:'',
            isValidAttribute: true
        }
        this.submit = this.submit.bind(this);
        this.setInfoText = this.setInfoText.bind(this);

        this.state.isValidAttribute = isValidAttribute(this.props.attrid, this.props.configAttribute);
        this.setConfigStore = this.setConfigStore.bind(this);
    }

    render() {
        return (
            <span>
                {this.state.isValidAttribute?<span/>:<Icon name={'warning'} color={'red'}/>}
                <Modal closeIcon trigger={<Icon name='edit' color='yellow'/>} id='decoder_modal' closeOnDimmerClick={false}>
                    <Header content={<div>{'Encode Config ' + this.props.attrid}
                        <Popup content={this.state.infoText} trigger={<Icon style={{float:'right'}} name='info circle' color='yellow'/>}/>
                    </div>} id='decoder_modal_header'/>
                    {isComponent500LightConfig(this.props.attrid) ?
                        <div>
                            <Modal.Content id='decoder_modal_content'>
                                <Config500Wizard attrid={this.props.attrid} attrtype='deviceAttribute'
                                                     datatype={this.props.datatype} datalength={this.props.datalength}
                                                     onSubmit={this.submit}
                                                    setInfoText={this.setInfoText}
                                                    configAttribute={this.props.configAttribute + ''}/>
                            </Modal.Content>
                        </div>
                        :(isComponent503ColorConfig(this.props.attrid) ?
                        <div>
                            <Modal.Content id='decoder_modal_content'>
                                <ColorDecoderComponent attrid={this.props.attrid} attrtype='deviceAttribute'
                                                       datatype={this.props.datatype} datalength={this.props.datalength}
                                                       onSubmitAndClose={this.submit}
                                                       onSubmit={this.state.props.onSubmit}
                                                       configAttribute={this.props.configAttribute + ''}
                                                       setInfoText={this.setInfoText}
                                />
                            </Modal.Content>
                        </div> : (isComponent501Config(this.props.attrid)?
                        <div>
                            <Modal.Content id='decoder_modal_content'>
                                <CCTableDecoderComponent attrid={this.props.attrid} attrtype='deviceAttribute'
                                                       datatype={this.props.datatype} datalength={this.props.datalength}
                                                       onSubmit={this.submit}
                                                       configAttribute={this.props.configAttribute + ''}
                                                       setInfoText={this.setInfoText}
                                                       setConfigStore={this.setConfigStore}
                                                       configStore={this.state.configStore}
                                />
                            </Modal.Content>
                        </div>: '')
                        )
                    }
                </Modal>
            </span>
        );
    }

    submit(event) {
        this.setState({isValidAttribute: isValidAttribute(this.props.attrid, event.target.userInput.value)})
        this.state.props.onSubmit(event);
        event.target.dispatchEvent(new KeyboardEvent('keydown', {'key': 'Escape'}));
        event.target.dispatchEvent(new KeyboardEvent('keydown', {'key': 'Esc'}));
    }

    setInfoText(text) {
        this.setState({infoText: text})
    }

    setConfigStore(configStore) {
        this.setState({configStore: configStore});
    }

}

function isComponent500LightConfig(attrId) {
    return attrId === 500;
}

function isComponent503ColorConfig(attrId) {
    return attrId === 503;
}

function isComponent501Config(attrId) {
    return attrId === 501;
}

function isModal(attrId, devIcon, attributes) {
    if (!isSparkleLightDevice(devIcon, attributes)) {
        return false;
    }
    return isComponent500LightConfig(attrId) || isComponent503ColorConfig(attrId) || isComponent501Config(attrId);
}

function isValidAttribute(attrId, configAttribute) {
    let isValid = attributeValidationMap[attrId]?.validate(configAttribute);
    if (isValid === undefined) {
        return true;
    }
    return isValid;
}

export {isModal}
