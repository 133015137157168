import { compose, lifecycle } from 'react-recompose';
import { connect } from 'react-redux';
import withRouter from '../../components/nav/with-router';

import handleErrorMessagesProcess from '../thunks/handleErrorMessagesProcess';
import SigninPage from '../../components/signin/SigninPage';
import signinProcess from '../thunks/signinProcess';

function mapStateToProps(state, ownProps) {
  return {
    signinErrorMsg: state.signinErrorMsg
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    handle_error_messages: msg => {
      return dispatch(handleErrorMessagesProcess(msg));
    },
    on_signin: (event, router, handler) => {
      return dispatch(signinProcess(event, router, handler));
    }
  };
}

const withlifecycle = lifecycle({
  componentDidMount() {}
});

const connectToStore = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default compose(
  connectToStore,
  withlifecycle
)(withRouter(SigninPage));
