import React from 'react';
import MainComponent from './MainComponent';

export default function MainPage({
  aferoAttribute,
  clear_deviceLogs,
  clear_rootReducer,
  connectionLogs,
  deviceAttribute,
  default_updatedErrorMsg,
  deviceProfile,
  deviceLogs,
  flag_repullApi,
  flag_displayCurrentConnection,
  get_aferoAttributes,
  get_deviceAttributes,
  get_deviceProfile,
  getAferoAttributesFlag,
  getDeviceAttributesFlag,
  getUserStateFlag,
  router,
  sort_attributes,
  displayCurrentConnectionFlag,
  update_attributes,
  userState,
  updateAferoAttributeFlag,
  updateDeviceAttributeFlag,
  flag_userState,
  flag_aferoAttributes,
  flag_deviceAttributes,
  update_device_attributes
}) {
  return (
    <div>
      <MainComponent
        aferoAttribute={aferoAttribute}
        clear_deviceLogs={clear_deviceLogs}
        clear_rootReducer={clear_rootReducer}
        connectionLogs={connectionLogs}
        deviceAttribute={deviceAttribute}
        default_updatedErrorMsg={default_updatedErrorMsg}
        deviceProfile={deviceProfile}
        deviceLogs={deviceLogs}
        flag_repullApi={flag_repullApi}
        flag_displayCurrentConnection={flag_displayCurrentConnection}
        get_aferoAttributes={get_aferoAttributes}
        get_deviceAttributes={get_deviceAttributes}
        get_deviceProfile={get_deviceProfile}
        getAferoAttributesFlag={getAferoAttributesFlag}
        getDeviceAttributesFlag={getDeviceAttributesFlag}
        getUserStateFlag={getUserStateFlag}
        router={router}
        sort_attributes={sort_attributes}
        displayCurrentConnectionFlag={displayCurrentConnectionFlag}
        update_attributes={update_attributes}
        update_device_attributes = {update_device_attributes}
        userState={userState}
        updateAferoAttributeFlag={updateAferoAttributeFlag}
        updateDeviceAttributeFlag={updateDeviceAttributeFlag}
        flag_userState={flag_userState}
        flag_aferoAttributes={flag_aferoAttributes}
        flag_deviceAttributes={flag_deviceAttributes}
      />
    </div>
  );
}
