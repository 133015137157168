import Big from 'big.js';

const CONFIGURED_SSID = 65004; ////see notes below
const WIFI_BAR = 65005; //see notes below
const STEADY_STATE = 65006; //see notes below
const COMMAND = 65012;
const LINKEDTIMESTAMP = 65015;
const PRIVATE_COMMUNICATION_CHANNEL = [65066]; //SERV-2452 exclude private attribute
const PRIVATE_SYSTEM = [60002, 65000, 65002, 65003, 65004, 65005, 65006, 65007, 65009, 65010, 65016, 65017, 65018, 65020]; //SERV-2452: exclude private attribute
const DEFAULT_ICON = 'https://cdn.afero.io/media-library/device_icon_unknown.png';

/*
device attributes: MCU, GPIO, VERSION, MCU_VERSION and APP
afero attribute: SYSTEM and COMMUNICATION_CHANNEL
*/
const AFERO_DATATYPES = {
  MCU: {
    min: 1,
    max: 1023
  },
  GPIO: {
    min: 1024,
    max: 1065
  },
  LOCAL_DEVICE_MCU: {
    min: 1201,
    max: 1300
  },
  TUNNELED_LOCAL_DEVICE_MCU: {
    min: 1301,
    max: 1400
  },
  VERSION: {
    min: 2001,
    max: 2100
  },
  MCU_VERSION: {
    min: 2101,
    max: 2200
  },
  APP: {
    min: 50000,
    max: 51000
  },
  HUB: {
    min: 51001,
    max: 52000
  },
  ENVIRONMENTAL: {
    min: 53001,
    max: 59000
  },
  OFFLINE_SCHEDULE: {
    min: 59002,
    max: 59999
  },
  SYSTEM: {
    min: 60001,
    max: 65022
  },
  COMMUNICATION_CHANNEL: {
    min: 65023,
    max: 65535
  }
};

/*update's validation to be used in updateAttributeProcess.js file*/
const ProfileValidator = {};

ProfileValidator.ATTRIBUTE_DATA_TYPES = {
  BOOLEAN: {
    name: 'BOOLEAN',
    min: 0,
    max: 1,
    size: 1,
    wholeNumber: false,
    isNumeric: false,
    isFixedLength: true,
    profileId: 1
  },
  SINT8: {
    name: 'SINT8',
    min: -128,
    max: 127,
    size: 1,
    wholeNumber: true,
    isNumeric: true,
    isFixedLength: true,
    profileId: 2
  },
  SINT16: {
    name: 'SINT16',
    min: -32768,
    max: 32767,
    size: 2,
    wholeNumber: true,
    isNumeric: true,
    isFixedLength: true,
    profileId: 3
  },
  SINT32: {
    name: 'SINT32',
    min: -2147483648,
    max: 2147483647,
    size: 4,
    wholeNumber: true,
    isNumeric: true,
    isFixedLength: true,
    profileId: 4
  },
  SINT64: {
    name: 'SINT64',
    min: window.goog.math.Long.fromString('-9223372036854775808'),
    max: window.goog.math.Long.fromString('9223372036854775807'),
    size: 8,
    wholeNumber: true,
    isNumeric: true,
    isFixedLength: true,
    profileId: 5
  },
  Q_15_16: {
    name: 'Q_15_16',
    min: new Big(2).pow(15).times(-1),
    max: new Big(2).pow(15).minus(new Big(2).pow(-16)),
    size: 4,
    wholeNumber: false,
    isNumeric: true,
    isFixedLength: true,
    profileId: 6
  },
  UTF8S: {
    name: 'UTF8S',
    min: 0,
    max: 255,
    size: 255,
    wholeNumber: false,
    isNumeric: false,
    isFixedLength: false,
    profileId: 20
  },
  BYTES: {
    name: 'BYTES',
    min: 0,
    max: 255,
    size: 255, // This depends on the size of the array, which can be anywhere between min and max
    wholeNumber: false,
    isNumeric: false,
    isFixedLength: false,
    profileId: 21
  },
  NULL: {
    name: 'NULL'
  }
};

ProfileValidator.DATATYPE_VALIDATION_RESULT = {
  OUT_OF_BOUNDS: 'OUT_OF_BOUNDS',
  NaN: 'NaN',
  UNASSIGNABLE: 'UNASSIGNABLE',
  VALID: 'VALID',
  BYTES_ODD_LENGTH: 'BYTES_ODD_LENGTH'
};

function isNumeric(string) {
  const RE = /^-{0,1}\d*\.{0,1}\d+$/;
  return RE.test(string);
}

ProfileValidator.validateDataType = function(stringValue, _dataType, _min, _max, _intCheck) {
  const dataType = _dataType.toUpperCase();
  const min = typeof _min === 'number' ? _min : ProfileValidator.ATTRIBUTE_DATA_TYPES[dataType].min;
  const max = typeof _max === 'number' ? _max : ProfileValidator.ATTRIBUTE_DATA_TYPES[dataType].max;
  const intCheck = typeof _intCheck === 'boolean' ? _intCheck : ProfileValidator.ATTRIBUTE_DATA_TYPES[dataType].wholeNumber;
  let ret = null;

  switch (dataType) {
    case ProfileValidator.ATTRIBUTE_DATA_TYPES.BOOLEAN.name:
      if (stringValue === null || !(stringValue === 'true' || stringValue === 'false')) {
        ret = ProfileValidator.DATATYPE_VALIDATION_RESULT.UNASSIGNABLE;
        return ret;
      }
      return ProfileValidator.DATATYPE_VALIDATION_RESULT.VALID;

    case ProfileValidator.ATTRIBUTE_DATA_TYPES.SINT8.name:
    case ProfileValidator.ATTRIBUTE_DATA_TYPES.SINT16.name:
    case ProfileValidator.ATTRIBUTE_DATA_TYPES.SINT32.name:
    case 'CUSTOM_RANGE': {
      if (stringValue === null || stringValue.length === 0) {
        ret = ProfileValidator.DATATYPE_VALIDATION_RESULT.NaN;
        return ret;
      }

      if (isNumeric(stringValue) === false) {
        ret = ProfileValidator.DATATYPE_VALIDATION_RESULT.NaN;
        return ret;
      }

      let val = parseInt(stringValue, 10);
      if (val.toString(10) !== stringValue) {
        ret = ProfileValidator.DATATYPE_VALIDATION_RESULT.UNASSIGNABLE;
        return ret;
      }

      if (val < min || val > max) {
        ret = ProfileValidator.DATATYPE_VALIDATION_RESULT.OUT_OF_BOUNDS;
        return ret;
      }

      // Check if value is actually an integer
      if (intCheck === true && val % 1 !== 0) {
        ret = ProfileValidator.DATATYPE_VALIDATION_RESULT.UNASSIGNABLE;
        return ret;
      }

      return ProfileValidator.DATATYPE_VALIDATION_RESULT.VALID;
    }
    case ProfileValidator.ATTRIBUTE_DATA_TYPES.Q_15_16.name: {
      if (stringValue.length === 0 || stringValue === null) {
        ret = ProfileValidator.DATATYPE_VALIDATION_RESULT.NaN;
        return ret;
      }

      if (isNumeric(stringValue) === false) {
        ret = ProfileValidator.DATATYPE_VALIDATION_RESULT.NaN;
        return ret;
      }

      let val = null;
      try {
        val = new Big(stringValue);
      } catch (e) {
        ret = ProfileValidator.DATATYPE_VALIDATION_RESULT.UNASSIGNABLE;
        return ret;
      }

      if (val.lt(min) || val.gt(max)) {
        ret = ProfileValidator.DATATYPE_VALIDATION_RESULT.OUT_OF_BOUNDS;
        return ret;
      }

      return ProfileValidator.DATATYPE_VALIDATION_RESULT.VALID;
    }
    case ProfileValidator.ATTRIBUTE_DATA_TYPES.SINT64.name: {
      if (stringValue.length === 0 || stringValue === null) {
        ret = ProfileValidator.DATATYPE_VALIDATION_RESULT.NaN;
        return ret;
      }

      if (isNumeric(stringValue) === false) {
        ret = ProfileValidator.DATATYPE_VALIDATION_RESULT.NaN;
        return ret;
      }

      let val = window.goog.math.Long.fromString(stringValue, 10);

      // There is no real other validation to perform as INT64 is pretty much the max...
      // If valueString is out of bounds, we will never know as the internal mechanism will always return the min/max
      // So... we try to parse it with goog.math.Long and then compare the output of toString() with stringValue, if they don't match then stringValue is unassignable
      if (val.toString(10) !== stringValue) {
        ret = ProfileValidator.DATATYPE_VALIDATION_RESULT.UNASSIGNABLE;
        return ret;
      }

      return ProfileValidator.DATATYPE_VALIDATION_RESULT.VALID;
    }
    case ProfileValidator.ATTRIBUTE_DATA_TYPES.UTF8S.name: {
      if (stringValue.length < min || stringValue.length > max) {
        return ProfileValidator.DATATYPE_VALIDATION_RESULT.OUT_OF_BOUNDS;
      }

      return ProfileValidator.DATATYPE_VALIDATION_RESULT.VALID;
    }
    case ProfileValidator.ATTRIBUTE_DATA_TYPES.BYTES.name: {
      if (stringValue.length % 2 !== 0) {
        return ProfileValidator.DATATYPE_VALIDATION_RESULT.BYTES_ODD_LENGTH;
      }

      const byteCount = stringValue.length / 2;

      if (byteCount < min || byteCount > max) {
        return ProfileValidator.DATATYPE_VALIDATION_RESULT.OUT_OF_BOUNDS;
      }

      for (let i = 0; i < stringValue.length; ++i) {
        const char = stringValue.charCodeAt(i);

        if ((char < 48 || char > 57) && (char < 65 || char > 70) && (char < 97 || char > 102)) {
          return ProfileValidator.DATATYPE_VALIDATION_RESULT.UNASSIGNABLE;
        }
      }

      return ProfileValidator.DATATYPE_VALIDATION_RESULT.VALID;
    }
    case ProfileValidator.ATTRIBUTE_DATA_TYPES.NULL.name:
      if (stringValue === null) {
        return ProfileValidator.DATATYPE_VALIDATION_RESULT.VALID;
      }
      return ProfileValidator.DATATYPE_VALIDATION_RESULT.OUT_OF_BOUNDS;
    default:
      return ProfileValidator.DATATYPE_VALIDATION_RESULT.UNASSIGNABLE;
  }
};

export {
  AFERO_DATATYPES,
  COMMAND,
  CONFIGURED_SSID,
  DEFAULT_ICON,
  PRIVATE_COMMUNICATION_CHANNEL,
  PRIVATE_SYSTEM,
  ProfileValidator,
  LINKEDTIMESTAMP,
  STEADY_STATE,
  WIFI_BAR
};

/*
-WIFI_BAR: Integer read-only attribute: Wi-Fi signal strength for UI purposes
-CONFIGURED_SSID: The SSID the Bento is currently configured to use
-STEADY_STATE: This is used to communicate the wifi state to the apps outside of the wifi setup.
Wi-Fi steady state (0 = Not Connected, 1 = Pending, 2 = Connected, 3 = Unknown Failure, 4 = Association Failed, 5 = Handshake Failed, 6 = Echo Failed, 7 = SSID Not Found, 8 = NTP Failed).
*/
