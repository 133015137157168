import React from 'react';
import SigninComponent from './SigninComponent';

export default function SigninPage({ handle_error_messages, signinErrorMsg,router, on_signin }) {
  return (
    <div>
      <SigninComponent
        handle_error_messages={handle_error_messages}
        signinErrorMsg={signinErrorMsg}
        router={router}
        on_signin={on_signin}
      />
    </div>
  );
}
