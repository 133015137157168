import React from 'react';
import AccountComponent from './AccountComponent';

export default function AccountPage({
  clear_rootReducer,
  flag_displayCurrentConnection,
  getUserStateFlag,
  router,
  connectionLogs,
  userState,
  store_currentComponent
}) {
  return (
    <div>
      <AccountComponent
        clear_rootReducer={clear_rootReducer}
        flag_displayCurrentConnection={flag_displayCurrentConnection}
        getUserStateFlag={getUserStateFlag}
        router={router}
        connectionLogs={connectionLogs}
        userState={userState}
        store_currentComponent={store_currentComponent}
      />
    </div>
  );
}
