/*user can clear out their current device-log contents*/

export default function clearDevLogsProcess() {
  return (dispatch, getState) => {
    let { deviceId } = JSON.parse(localStorage.getItem('selectedDeviceObj'));
    let devLogsUpdated = getState().deviceLogs;
    devLogsUpdated[deviceId].logs = [];

    dispatch({ type: 'CLEAR_DEVICE_LOGS', deviceLogs: devLogsUpdated });
  };
}
