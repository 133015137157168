import React from 'react';

export default function Error401Component() {
  return (
    <div className='error-404-container'>
      <div className='error-404-img-container' />
      <div className='error-404-text-container'>
        <div className='text-container'>
          <h1 id='error404H1'>401</h1>
          <h2 id='error404H2'>
            Uh oh... You do not have proper role authentication to access this page.
          </h2>
          <p>
            <a id='goHome404Error' href='/'>
              Go to sign in
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
