import React from 'react';
import env from '../../env';

export default function FooterComponent() {
  return (
    <div className='footer-container'>
      <nav id='footerNav-item'>
        {'\u00A9'} 2015 - {new Date().getFullYear()} Afero, Inc. |{' '}
        <a id='aferoLegalURL' href={env.url_legal} target='_blank' rel='noopener noreferrer'>
          Legal
        </a>
        &nbsp;| &nbsp;
        <a id='aferoPrivacyURL' href={env.url_privacy} target='_blank' rel='noopener noreferrer'>
          Privacy
        </a>
      </nav>
    </div>
  );
}
