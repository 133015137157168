import { format, parse } from 'date-fns';

/*defaulting Wifi and bluetooth rssi value*/

export default function defaultConnectionLogsProcess(router) {
  return async (dispatch, getState) => {
    try {
      let deviceDetails = JSON.parse(localStorage.getItem('accountDevices'));
      let newObj = {};

      deviceDetails.forEach(dev => {
        //default bluetooth value. profile.presentation excludes all hubs
        if (dev.deviceState.direct === false && dev.profile.presentation) {
          let bluetoothObj = {};
          bluetoothObj.signalStrength = dev.deviceState.rssi;
          bluetoothObj.timestamp = format(parse(dev.deviceState.updatedTimestamp), 'HH:mm:ss.SSS');

          let connectionScope = {};
          connectionScope.bluetoothSignal = bluetoothObj;
          connectionScope.wifiSignal = null;
          connectionScope.activeConnection = 'bluetooth';

          newObj[dev.deviceId] = {
            connections: connectionScope
          };
        } else if (dev.deviceState.direct === true && dev.profile.presentation) {
          //default wifi value
          let wifiObj = {};

          dev.attributes.forEach(obj => {
            if (obj.id === 65004) {
              wifiObj.ssId = obj.value;
              wifiObj.timestamp = format(parse(obj.updatedTimestamp), 'HH:mm:ss.SSS');
            } else if (obj.id === 65005) {
              wifiObj.wifiBar = obj.value;
              wifiObj.timestamp = format(parse(obj.updatedTimestamp), 'HH:mm:ss.SSS');
            } else if (obj.id === 65006) {
              wifiObj.steadyState = obj.value;
              wifiObj.timestamp = format(parse(obj.updatedTimestamp), 'HH:mm:ss.SSS');
            }
          });

          let steadyStateDecode = {
            '0': 'Not Connected',
            '1': 'Pending',
            '2': 'Connected',
            '3': 'Unknown Failure',
            '4': 'Association Failed',
            '5': 'Handshake Failed',
            '6': 'Echo Failed',
            '7': 'SSID Not Found'
          };
          wifiObj.steadyState = wifiObj.steadyState + ' (' + steadyStateDecode[wifiObj.steadyState] + ')';

          let connectionScope = {};
          connectionScope.bluetoothSignal = null;
          connectionScope.wifiSignal = wifiObj;
          connectionScope.activeConnection = 'wifi';

          newObj[dev.deviceId] = {
            connections: connectionScope
          };
        }
      });
      dispatch({ type: 'DEFAULT_CONNECTION_LOGS', connectionLogs: newObj });
    } catch (error) {
      console.log(error);
    }
  };
}
