import React, { useState } from 'react';
import { List, Table, Tab, Button, Icon, Form } from 'semantic-ui-react';
import ReactTooltip from 'react-tooltip';
import parse from 'date-fns/parse';
import format from 'date-fns/format';
import DecoderModalComponent, { isModal } from "./encoder/DecoderModalComponent";
import ConfirmationModalComponent from './ConfirmationModalComponent';
import FilterComponent from "./filters/FilterComponent";

export default function AttributeComponent({
  aferoAttribute,
  aferoDateIconColor,
  aferoDateIconType,
  aferoNameIconColor,
  aferoNameIconType,
  aferoIdIconColor,
  aferoIdIconType,
  deviceAttribute,
  devIdIconColor,
  devIdIconType,
  devDateIconColor,
  devDateIconType,
  devNameIconColor,
  devNameIconType,
  propEdited,
  handle_downloadAferoAttributes,
  handle_downloadDeviceAttributes,
  handle_downloadSparkleAttributes,
  handle_closeInputForm,
  handle_sortDeviceAttribute,
  handle_sortAferoAttribute,
  handle_updateAttributes,
  handle_userInput,
  userState,
  handle_devAttrFilter, setDevAttributesFilter, attrFilter,
  handle_update500Attributes,
  deviceProfile
}) {
  const [uploadedJSONFile, setUploadedJSONFile] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  function panes() {
    const handle_fileUploadClick = () => {
      const element = document.getElementById("file_upload");
      if (element) {
        element.click();
      }
    }

    const handle_fileUpload = (event) => {
      const file = event?.target?.files?.[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const fileContents = e.target.result;
          const config500Values = [500, 501, 503, 510];
          const config500 = [];
          const errorMessages = [];

          setErrorMessage("");
          setOpenModal(false);
          try {
            const parsedJSONFile = JSON.parse(fileContents);
            if (parsedJSONFile && Array.isArray(parsedJSONFile.attributes)) {
              for (const item of parsedJSONFile.attributes) {
                if (item.attributeId) {
                  if (item.value) {
                    if (config500Values.includes(Number(item.attributeId))) {
                      config500.push(item);
                    }
                  } else if (config500Values.includes(item.attributeId)) {
                    errorMessages.push(`Value of ${item.attributeId} Attribute is missing!!`);
                  } else {
                    errorMessages.push(`Incorrect JSON Format, Value is missing for Attribute ID ${item.attributeId}!!`);
                  }
                }
              }
              if (errorMessages.length > 0) {
                setErrorMessage(errorMessages.join(' '));
                setOpenModal(true);
              } else {
                parsedJSONFile.attributes500 = config500;
                setUploadedJSONFile(parsedJSONFile);
                setOpenModal(true);
              }
            }
          } catch (error) {
            setErrorMessage(`Failed to Parse JSON file: ${error}`);
            setOpenModal(true);
          }
        };
        reader.readAsText(file);
      }
    };


    return [
      {
        menuItem: 'DEVICE ATTRIBUTES',
        render: () => (
          <Tab.Pane id='attributeValue' attached={false}>
            <FilterComponent device={deviceAttribute}
              handle_devAttrFilter={handle_devAttrFilter}
              setFilter={setDevAttributesFilter}
              attrFilter={attrFilter}
              exportSparkleConfig={handle_downloadSparkleAttributes}
              importSparkleConfig={handle_fileUploadClick}
              userState={userState} />
            <Table inverted celled selectable compact='very' basic='very'>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    Attribute ID
                    <Icon
                      id='deviceIdSort'
                      size='large'
                      name={devIdIconType}
                      style={{ color: devIdIconColor }}
                      data-column='attrId'
                      data-attrtype='deviceAttr'
                      onClick={handle_sortDeviceAttribute}
                    />
                    <Button
                      circular
                      size='mini'
                      icon='download'
                      id='downloadSparkleContents'
                      data-tip
                      data-for='deviceAttributeDownload'
                      onClick={handle_downloadDeviceAttributes}
                    />
                    <ReactTooltip id='deviceAttributeDownload' type='light' effect='solid' place='top'>
                      <span>Download Contents</span>
                    </ReactTooltip>
                    <input type="file" id="file_upload" style={{ display: 'none' }} onChange={handle_fileUpload} accept="application/json" />
                    
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    Attribute Name
                    <Icon
                      id='deviceNameSort'
                      size='large'
                      name={devNameIconType}
                      style={{ color: devNameIconColor }}
                      data-column='attrName'
                      data-attrtype='deviceAttr'
                      onClick={handle_sortDeviceAttribute}
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell>Value</Table.HeaderCell>
                  <Table.HeaderCell>Operations</Table.HeaderCell>
                  <Table.HeaderCell>
                    Last Update
                    <Icon
                      id='deviceDateSort'
                      size='large'
                      name={devDateIconType}
                      style={{ color: devDateIconColor }}
                      data-column='attrDate'
                      data-attrtype='deviceAttr'
                      onClick={handle_sortDeviceAttribute}
                    />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {deviceAttribute.attributes.filter((attr) => attrFilter(attr)).map(props => {
                  return (
                    <Table.Row key={props.attributeId}>
                      <Table.Cell>{props.attributeId}</Table.Cell>
                      <Table.Cell>{props.semanticType}</Table.Cell>
                      {props.operations.includes('WRITE') && props.available === true ? (
                          <Table.Cell>
                            {!propEdited[props.attributeId] ? (
                              <div>
                                {props.value}{' '}
                                {isModalForEdit(props.attributeId, userState, deviceAttribute) ? (
                                  <DecoderModalComponent
                                    attrid={props.attributeId} attrtype='deviceAttribute'
                                    datatype={props.dataType} datalength={props.length}
                                    onSubmit={handle_updateAttributes}
                                    configAttribute={props.value + ''}
                                  />
                                ) : <Icon onClick={handle_userInput} aria-label='beginEdit' data-attrid={props.attributeId} name='pencil' color='yellow' />
                                }
                              </div>
                            ) : (
                              <div>
                                <Form
                                  size='mini'
                                  data-attrid={props.attributeId}
                                  data-attrtype='deviceAttribute'
                                  data-datatype={props.dataType}
                                  data-datalength={props.length}
                                  onSubmit={handle_updateAttributes}
                                >
                                  <Form.Group>
                                    <Form.Input defaultValue={props.value} id='userInput' autoFocus />
                                    <Button.Group>
                                      <Form.Button id='checkIconButton' icon aria-label='submitEdit'>
                                        <Icon name='check' />
                                      </Form.Button>
                                      <Form.Button id='closeIconButton' icon>
                                        <Icon
                                          name='close'
                                          data-attrid={props.attributeId}
                                          data-attrtype='deviceAttribute'
                                          onClick={handle_closeInputForm}
                                        />
                                      </Form.Button>
                                    </Button.Group>
                                  </Form.Group>
                                </Form>
                                {props.updatedErrorMsg !== '' ? <p id='updatedErrorMsg'>{props.updatedErrorMsg}</p> : ''}
                              </div>
                            )}
                          </Table.Cell>
                        ) : (
                          <Table.Cell>{props.value}</Table.Cell>
                        )}
                      <Table.Cell>
                        <List divided inverted horizontal>
                          {props.operations.map((txt, i) => {
                            return (
                              <List.Item key={i}>
                                <List.Content>
                                  <List>{txt}</List>
                                </List.Content>
                              </List.Item>
                            );
                          })}
                        </List>
                      </Table.Cell>
                      <Table.Cell>{format(parse(props.updatedTimestamp).toString(), 'MMM Do YYYY, h:mm A')}</Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </Tab.Pane>
        )
      },
      {
        menuItem: 'AFERO SYSTEM ATTRIBUTES',
        render: () => (
          <Tab.Pane id='attributeValue' attached={false}>
            <span id='system-attribute-devlink'>
              <em>
                For more information on Afero system attributes,{' '}
                <a href='https://developer.afero.io/AttrRegistry' target='_blank' rel='noopener noreferrer'>
                  click here.
                </a>
              </em>
            </span>
            <Table inverted celled selectable compact='very' basic='very'>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    Attribute ID
                    <Icon
                      id='aferoIdSort'
                      size='large'
                      name={aferoIdIconType}
                      style={{ color: aferoIdIconColor }}
                      data-column='attrId'
                      data-attrtype='aferoAttr'
                      onClick={handle_sortAferoAttribute}
                    />
                    <Button
                      circular
                      size='mini'
                      icon='download'
                      id='downloadContents'
                      data-tip
                      data-for='aferoAttributeTip'
                      onClick={handle_downloadAferoAttributes}
                    />
                    <ReactTooltip id='aferoAttributeTip' type='light' effect='solid' place='top'>
                      <span>Download contents</span>
                    </ReactTooltip>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    Attribute Name
                    <Icon
                      id='aferoNameSort'
                      size='large'
                      name={aferoNameIconType}
                      style={{ color: aferoNameIconColor }}
                      data-column='attrName'
                      data-attrtype='aferoAttr'
                      onClick={handle_sortAferoAttribute}
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell>Value</Table.HeaderCell>
                  <Table.HeaderCell>Operations</Table.HeaderCell>
                  <Table.HeaderCell>
                    Last Update
                    <Icon
                      id='aferoDateSort'
                      size='large'
                      name={aferoDateIconType}
                      style={{ color: aferoDateIconColor }}
                      data-column='attrDate'
                      data-attrtype='aferoAttr'
                      onClick={handle_sortAferoAttribute}
                    />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {aferoAttribute.attributes.map(props => {
                  return (
                    <Table.Row key={props.attributeId}>
                      <Table.Cell>{props.attributeId}</Table.Cell>
                      <Table.Cell>{props.semanticType}</Table.Cell>
                      {props.operations.includes('WRITE') && props.available === true ? (
                        <Table.Cell>
                          {!propEdited[props.attributeId] ? (
                            <div>
                              {props.value}{' '}
                              <Icon data-attrid={props.attributeId} name='pencil' color='yellow' onClick={handle_userInput} />
                            </div>
                          ) : (
                            <div>
                              <Form
                                data-attrid={props.attributeId}
                                data-attrtype='aferoAttribute'
                                data-datatype={props.dataType}
                                data-datalength={props.length}
                                size='mini'
                                onSubmit={handle_updateAttributes}
                              >
                                <Form.Group>
                                  <Form.Input defaultValue={props.value} id='userInput' autoFocus />
                                  <Button.Group>
                                    <Form.Button id='checkIconButton' icon>
                                      <Icon name='check' />
                                    </Form.Button>
                                    <Form.Button id='closeIconButton' icon>
                                      <Icon
                                        name='close'
                                        data-attrid={props.attributeId}
                                        data-attrtype='aferoAttribute'
                                        onClick={handle_closeInputForm}
                                      />
                                    </Form.Button>
                                  </Button.Group>
                                </Form.Group>
                              </Form>
                              {props.updatedErrorMsg !== '' ? <p id='updatedErrorMsg'>{props.updatedErrorMsg}</p> : null}
                            </div>
                          )}
                        </Table.Cell>
                      ) : (
                        <Table.Cell>{props.value}</Table.Cell>
                      )}

                      <Table.Cell>
                        <List divided inverted horizontal>
                          {props.operations.map((txt, i) => {
                            return (
                              <List.Item key={i}>
                                <List.Content>
                                  <List>{txt}</List>
                                </List.Content>
                              </List.Item>
                            );
                          })}
                        </List>
                      </Table.Cell>

                      <Table.Cell>{format(parse(props.updatedTimestamp).toString(), 'MMM Do YYYY, h:mm A')}</Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </Tab.Pane>
        )
      }
    ];
  }

  const closeModal = () => {
    setOpenModal(false);
  }

  const setDataFromJSONFileToLocalStorage = (data) => {
    if (data) {
      let { deviceId } = JSON.parse(localStorage.getItem('selectedDeviceObj'));
      const wizardData = JSON.parse(localStorage.getItem(`${deviceId}_500`));
    
      if (!wizardData) {
        const attributeConfig500 = data?.attributes500.find((item) => item?.attributeId === 500);
        if (attributeConfig500) {
          localStorage.setItem(`${deviceId}_500`, JSON.stringify(attributeConfig500?.questionaire));
        }
        return;
      }
    
      
      const attributeConfig500 = data?.attributes500.find((item) => item?.attributeId === 500);
      if (attributeConfig500 && attributeConfig500?.questionaire) {
        const updatedWizardData = wizardData.map((wizardItem) => {
          const correspondingQuestion = attributeConfig500.questionaire.find(
            (question) => question.key === wizardItem.key
          );
          if (correspondingQuestion && correspondingQuestion.value !== wizardItem.value) {
            return { ...wizardItem, value: correspondingQuestion.value };
          }
          return wizardItem;
        });
    
        localStorage.setItem(`${deviceId}_500`, JSON.stringify(updatedWizardData));
      }
      return;
    }
    
    
  }

  const handle_uploadClick = () => {
    setDataFromJSONFileToLocalStorage(uploadedJSONFile)
    handle_update500Attributes(uploadedJSONFile);
    setOpenModal(false);
  }

  return (
    <div className='attribute-panel-wrapper'>
      <Tab
        menu={{
          pointing: true,
          fluid: true,
          inverted: true,
          color: 'black'
        }}
        panes={panes()}
      />
      <ConfirmationModalComponent
        openModal={openModal}
        deviceName={aferoAttribute?.friendlyName}
        closeConfimrationModal={closeModal}
        pushConfiguration={handle_uploadClick}
        errorMessage={errorMessage} />
    </div>
  );
}

function isModalForEdit(attrId, userState, device) {
  let foundDevice = userState.devDetails.find(dev => dev.deviceId === device.deviceId);
  return (foundDevice ? isModal(attrId, foundDevice.deviceIconUrl, device.attributes) : false);
}
