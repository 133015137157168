import React, { useEffect } from 'react';
import { Header, Modal, Button } from 'semantic-ui-react';

export default function ConfirmationComponent({
    open,
    onClose,
    onConfirm,
    handle_updateDeviceAttribute
}) {

    const updateAttribute = () => {

        const accountId = localStorage.getItem('accountId');
        let { deviceId } = JSON.parse(localStorage.getItem('selectedDeviceObj'));
        if (accountId && deviceId)
            handle_updateDeviceAttribute('aferoAttribute', accountId, deviceId);
        onClose();
    }
    useEffect(() => {
        const element = document.getElementsByClassName("popup")[0];
        if (element) {
            element.style = "display: none !important"
        }
    }, [open])

    return (
        <Modal open={open} size='tiny' onClose={onClose}>
            <Header id='decoder_modal_content' content='WARNING' />
            <Modal.Content id='decoder_modal_content'>
                <p>
                    Enabling Factory Test Mode (FTM) will also wipe user settings and reboot the device.
                    Are you sure?
                </p>
            </Modal.Content>
            <Modal.Actions id='decoder_modal_content'>
                <Button
                    style={{ backgroundColor: '#ffac3e' }}
                    onClick={updateAttribute}
                >
                    Yes
                </Button>
                <Button onClick={() => onConfirm(false)}>No</Button>
            </Modal.Actions>
        </Modal>
    );
}
