import React from 'react';
import aferoLogo from '../../images/afero-inspector-logo.svg';
import ReactTooltip from 'react-tooltip';
import { Icon } from 'semantic-ui-react';

export default function HeaderHomeComponent({ handle_routeAccount, handle_routeHome, handle_routeSignOut }) {
  return (
    <div style={{ background: '#454b4e' }} className='headerHome-container'>
      <div id='home-aferoLogo-item'>
        <a href='/home'>
          <img src={aferoLogo} alt='Afero, Inc.' />
        </a>
      </div>

      <div id='home-homeRoute-item' onClick={handle_routeHome}>
        <Icon data-tip data-for='homeTip' size='large' name='home' />
        <ReactTooltip id='homeTip' type='light' effect='solid'>
          <span>Home</span>
        </ReactTooltip>
      </div>

      <div id='home-accountRoute-item' onClick={handle_routeAccount}>
        <Icon data-tip data-for='userTip' size='large' name='user' />
        <ReactTooltip id='userTip' type='light' effect='solid'>
          <span>Account info</span>
        </ReactTooltip>
      </div>

      <div id='home-guideRoute-item'>
        <a href='https://developer.afero.io/Inspector' target='_blank' rel='noopener noreferrer'>
          <Icon data-tip data-for='GuideTip' size='large' name='question' />
        </a>
        <ReactTooltip id='GuideTip' type='light' effect='solid'>
          <span>User Guide</span>
        </ReactTooltip>
      </div>

      <div id='home-signoutRoute-item' onClick={handle_routeSignOut}>
        <Icon data-tip data-for='signoutTip' size='large' name='sign out' />
        <ReactTooltip id='signoutTip' type='light' effect='solid'>
          <span>Sign out</span>
        </ReactTooltip>
      </div>
    </div>
  );
}
