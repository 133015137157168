import {isValid500Attribute} from "../components/main/encoder/Config500Wizard";

let attributeValidationMap = {
    '500': {
        attribute:500,
        validate: isValid500Attribute
    }
}


export {attributeValidationMap};
