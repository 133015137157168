import { compose, lifecycle } from 'react-recompose';
import { connect } from 'react-redux';
import withRouter from '../../components/nav/with-router';
import HomePage from '../../components/home/HomePage';

import clearReducerAndStorageProcess from '../thunks/clearReducerAndStorageProcess';
import defaultConnectionLogsProcess from '../thunks/defaultConnectionLogsProcess';
import flag_repullApiProcess from '../thunks/flag_repullApiProcess';
import flag_displayCurrentConnectionProcess from '../thunks/flag_displayCurrentConnectionProcess';
import flag_userStateProcess from '../thunks/flag_userStateProcess';
import getUserStateProcess from '../thunks/getUserStateProcess';
import storeCurrentComponentProcess from '../thunks/storeCurrentComponentProcess';
import {handleKeycloakRefreshPage} from "../../api/authentication";
import storeAccountDevices from "../thunks/storeAccountDevices";

function mapStateToProps(state, ownProps) {
  return {
    deviceAttribute: state.deviceAttribute,
    getUserStateFlag: state.getUserStateFlag,
    connectionLogs: state.connectionLogs,
    repullApiFlag: state.repullApiFlag,
    userState: state.userState
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    clear_rootReducer: () => {
      dispatch(clearReducerAndStorageProcess());
    },
    default_connectionLogs: router => {
      dispatch(defaultConnectionLogsProcess(router));
    },
    flag_repullApi: boolean => {
      dispatch(flag_repullApiProcess(boolean));
    },
    flag_displayCurrentConnection: boolean => {
      dispatch(flag_displayCurrentConnectionProcess(boolean));
    },
    flag_userState: boolean => {
      dispatch(flag_userStateProcess(boolean));
    },
    get_userState: router => {
      dispatch(getUserStateProcess(router));
    },
    store_currentComponent: component => {
      dispatch(storeCurrentComponentProcess(component));
    },
    findAccountDevice: async router => {
      await storeAccountDevices(router, dispatch);
    }
  };
}

const withlifecycle = lifecycle({

  componentDidMount() {
    this.props.store_currentComponent('homeComponent');
    let { flag_displayCurrentConnection, router } = this.props;
    handleKeycloakRefreshPage(() => {
      this.props.findAccountDevice(router).then(()=>{
        this.props.get_userState(router);
        this.props.default_connectionLogs(router);
      })
      .catch(error => {
        const host = window.location.origin;
        window.location.href = `${host}`;
      });
    })
    flag_displayCurrentConnection(false); //SERV-2565
  },

  /*LifeCycle: Growth/Update*/
  componentDidUpdate(prevProps, prevState) {
    let { router, repullApiFlag } = this.props;

    if (repullApiFlag === true) {
      this.props.findAccountDevice(router).then(()=>{
        this.props.get_userState(router);
      })
      this.props.flag_repullApi(false);
    }
  },

  /*LifeCycle: Death/Unmount*/
  componentWillUnmount() {
    this.props.flag_userState(false);
  }
});

const connectToStore = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default compose(
  connectToStore,
  withlifecycle
)(withRouter(HomePage));
