import * as auth from '../../api/authentication';
import { getAccountDevices } from "../../api/http_request_wrapper";

/*each device will have a default log of empty array, and contents will be push into
the empty array by one of the middlewares*/

export default async function storeAccountDevices(router, dispatch, expansions = 'attributes,state,profile') {
  try {
    const token = localStorage.getItem("kc_token")
    const refreshToken = localStorage.getItem("kc_refreshToken")
    if ((token && refreshToken) && (token !== "undefined" && refreshToken !== "undefined")) {
      await auth.handleAuthentication(router, dispatch);

      /*parsing data for Devices details by accountId*/
      const accountId = localStorage.getItem('accountId');
      let devs = await getAccountDevices(accountId, expansions)
      localStorage.setItem('accountDevices', JSON.stringify(devs));
      return true;
    } else {
      return Promise.reject(new Error("Token or refresh token may be undefined"));
    }
  } catch (error) {
    console.log(error);
    router?.navigate(`/error`);
  }
}
