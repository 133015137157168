import React from 'react';
import ReactTooltip from 'react-tooltip';
import { Icon } from 'semantic-ui-react';

export default function HeaderMainComponent({ handle_routeAccount, handle_routeHome, handle_routeSignOut, userState }) {
  let { deviceId } = JSON.parse(localStorage.getItem('selectedDeviceObj'));
  return (
    <div style={{ background: '#454b4e' }} className='headerMain-container'>
      {userState.devDetails.map((dev, i) => {
        if (dev.deviceId === deviceId) {
          return (
            <div key={i} id='main-friendlyName-item'>
              <img id='deviceImg' alt='deviceImg' src={dev.deviceIconUrl} />
              <h1 id='deviceHeader'>{dev.friendlyName}</h1>
            </div>
          );
        }
        return true;
      })}

      <div id='main-homeRoute-item' onClick={handle_routeHome}>
        <Icon data-tip data-for='homeTip' size='large' name='home' />
        <ReactTooltip id='homeTip' type='light' effect='solid'>
          <span>Home</span>
        </ReactTooltip>
      </div>

      <div id='main-accountRoute-item' onClick={handle_routeAccount}>
        <Icon data-tip data-for='userTip' size='large' name='user' />
        <ReactTooltip id='userTip' type='light' effect='solid'>
          <span>Account info</span>
        </ReactTooltip>
      </div>

      <div id='main-guideRoute-item'>
        <a href='https://developer.afero.io/Inspector' target='_blank' rel='noopener noreferrer'>
          <Icon data-tip data-for='GuideTip' size='large' name='question' />
        </a>
        <ReactTooltip id='GuideTip' type='light' effect='solid'>
          <span>User Guide</span>
        </ReactTooltip>
      </div>

      <div id='main-signoutRoute-item' onClick={handle_routeSignOut}>
        <Icon data-tip data-for='signoutTip' size='large' name='sign out' />
        <ReactTooltip id='signoutTip' type='light' effect='solid'>
          <span>Sign out</span>
        </ReactTooltip>
      </div>
    </div>
  );
}
