import React, { Component } from 'react';
import {
    Button,
    ButtonGroup,
    Form, Icon, Message,
    Table
} from 'semantic-ui-react';
import Config501 from "../../../util/encoder/configrules/Config501";
import EditField from "./EditField";
import ClearSubmitComponent from "./ClearSubmitComponent";
import WizardComponent from "./WizardComponent";

export default class CCTableDecoderComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            props: props,
            config: {},
            invalidFields: false,
            wizardComplete: false
        };
        this.state.config = (this.props.configStore) ? this.props.configStore : new Config501(this.useConfigAttr(), this.props.configStore);
        this.state.config.decode()
        this.submit = this.submit.bind(this)
        this.completeWizard = this.completeWizard.bind(this)
        this.props.setInfoText('Lightbulb Color Temperature Table Configuration Attribute. ' +
            'Set your own CCT table for this product.');
        this.state.page = 1;
        if (this.props.configStore) {
            this.state.wizardComplete = true;
            this.state.config.decodedAttributes.version.runRules();
            this.addMinCCT();
            this.addMidCCT();
            this.addMaxCCT();
            this.validForm(true);
        }
    }

    render() {
        return (
            <div>
                {!this.state.wizardComplete ?
                    <WizardComponent attrid={this.props.attrid}
                        config={this.state.config}
                        page={this.state.page}
                        pagesOrder={this.state.pagesOrder}
                        submitButton={(page, pagesOrder) =>
                            <ButtonGroup color={'orange'}>
                                <Button id='encodeButton' className={'submitButton hiddenSubmitButton'}
                                    onClick={() => {
                                        this.completeWizard(pagesOrder)
                                    }}>
                                    Next
                                </Button>
                            </ButtonGroup>}
                    />
                    :
                    <div>
                        <Message warning hidden={!this.state.invalidFields}>
                            <Message.Header>
                                Warning
                            </Message.Header>
                            <Message.Content id='warnings'>
                                {typeof this.state.invalidFields === 'string' ? this.state.invalidFields : 'A field in the table is invalid.'}
                            </Message.Content>
                        </Message>
                        <Form onSubmit={event => this.submit(event)}
                            data-attrid={this.props.attrid}
                            data-attrtype={this.props.attrtype}
                            data-datatype={this.props.datatype}
                            data-datalength={this.props.datalength}>
                            <div className="decoder-component" id='decoder_item'>
                                <Table fixed inverted celled compact='very' basic='very'>
                                    <Table.Header>
                                        <Table.Row key={'header'}>
                                            {this.state.config.decodedAttributes.headings.map((value) => {
                                                return (<Table.HeaderCell key={value}>
                                                    {value}
                                                </Table.HeaderCell>
                                                )
                                            })}
                                            <Table.HeaderCell key={'removeRow'} width={1} />
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {this.state.config.decodedAttributes.groups.map((group, index) => {
                                            return (
                                                <Table.Row key={group.attributes[0].title + 'row' + index}>
                                                    {group.attributes.map((attribute, index) => {
                                                        return (
                                                            <Table.Cell onBlur={() => this.validForm()} key={attribute.title} style={{ overflow: 'visible' }}>
                                                                <EditField attribute={attribute} prevAttribute={group.attributes[index - 1]} justEdit={true}
                                                                    dropdownChange={() => this.validForm()} textChange={() => this.validForm()} />
                                                            </Table.Cell>
                                                        )
                                                    })}
                                                    <Table.Cell width={1}>
                                                        {(index === 0 || index === this.state.config.decodedAttributes.groups.length - 1) ?
                                                            "" : <Icon link name='minus' onClick={() => this.removeGroup(index)} />
                                                        }
                                                    </Table.Cell>
                                                </Table.Row>
                                            )
                                        })}
                                        <Table.Row>
                                            <Table.Cell>
                                                <Icon link name='add' onClick={() => this.addGroup()}
                                                    disabled={!this.state.config.nextAvailableCCT()} />
                                            </Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                            </div>
                            <table style={{ width: '100%' }}>
                                <tr>
                                    <td>
                                        <div className='backNext'>
                                            <Button onClick={() => this.back()}>Back</Button>
                                        </div>
                                    </td>
                                    <td>
                                        <ClearSubmitComponent parentProps={this.props}
                                            warnings={this.state.invalidFields}
                                            submit={() => {
                                                this.setState({ rebootDevice: true })
                                            }}
                                            submitWithoutValue={(event) => {
                                                this.state.config.resetConfigValue(this.state.config.configValue.substring(0, 2));
                                                this.props.setConfigStore(this.state.config);
                                                this.state.props.onSubmit(event)
                                            }}
                                            style={{ float: 'right' }}
                                        />
                                    </td>
                                </tr>
                            </table>
                        </Form>
                    </div>}
            </div>
        );
    }

    back() {
        this.setState({ wizardComplete: false });
    }

    setConfigDecodedAttribute() {
        this.state.config.encode();
        this.state.config.decode();
    }

    completeWizard(pagesOrder) {
        if (this.state.config.decodedAttributes.version.rawValue === '01' && this.props.configAttribute.substring(0, 2) !== '01') {
            this.state.config.resetConfigValue('01')
        } else if (this.state.config.decodedAttributes.version.rawValue !== '01' && this.props.configAttribute.substring(0, 2) === '01') {
            this.state.config.resetConfigValue(this.state.config.decodedAttributes.version.rawValue)
        } else {
            this.state.config.resetConfigValue((this.props.configAttribute !== 'undefined') ?
                this.state.config.decodedAttributes.version.rawValue + this.props.configAttribute.substring(2) :
                this.state.config.decodedAttributes.version.rawValue
            )
        }
        this.props.setConfigStore(this.state.config)
        this.state.config.decodedAttributes.version.runRules();
        this.addMinCCT();
        this.addMidCCT();
        this.addMaxCCT();
        this.setConfigDecodedAttribute();
        this.setState({ wizardComplete: true, pagesOrder: pagesOrder, page: 3 }, () => { this.validForm() });
    }

    submit(event) {
        this.state.config.sort();
        this.state.config.encode();
        event.target.userInput = { value: this.state.config.configValue };
        event.target.rebootDevice = event.target.querySelector('#rebootCheckbox').checked;
        this.state.props.onSubmit(event);
    }

    submitEmpty(event) {
        event.target.userInput = { value: null };
        this.state.props.onSubmit(event);
    }

    updateAllAttributes() {
        let inputs = document.querySelectorAll('#configInput, #configDropdown');
        for (let i = 0; i < inputs.length; i++) {
            let dataset = (inputs[i].id === 'configDropdown') ? inputs[i].dataset : inputs[i].parentElement.dataset;
            let matchAttribute = JSON.parse(dataset.attribute);
            let value = (inputs[i].id === 'configDropdown') ? inputs[i].firstChild.textContent : inputs[i].value;
            this.updateAttribute(matchAttribute, value)
        }
    }

    updateAttribute(matchAttribute, input) {
        let attribute = this.state.config.findAttribute(matchAttribute.id);
        attribute.setReadableValue(input);
    }

    addGroup() {
        this.state.config.addGroup();
        this.setState({ refresh: true });
        this.state.config.sort();
    }

    removeGroup(index) {
        this.state.config.removeGroup(index);
        this.setState({ refresh: true })
        this.validForm(true);
    }

    validForm(noUpdate) {
        if (!noUpdate) {
            this.updateAllAttributes();
        }
        let errors = document.querySelectorAll('.error');
        let haveMinMax = this.hasMinMaxCCT();
        if (errors.length > 0 || !haveMinMax) {
            let errorMessage = (!haveMinMax) ? `Color Temp must include minimum(${this.state.config.minColorTemp}) and maximum(${this.state.config.maxColorTemp}). ` : '';
            this.setState({ invalidFields: (errorMessage.length > 1) ? errorMessage : true });
        } else {
            this.state.config.sort();
            this.setState({ invalidFields: false });
        }
    }

    hasMinMaxCCT() {
        let hasMinCCT = false;
        let hasMaxCCT = false;
        this.state.config.decodedAttributes.groups.forEach((group) => {
            if (group.attributes[0].readableValue === this.state.config.minColorTemp) {
                hasMinCCT = true;
            }
            if (group.attributes[0].readableValue === this.state.config.maxColorTemp) {
                hasMaxCCT = true;
            }
        });
        return hasMaxCCT && hasMinCCT;
    }

    addMinCCT() {
        let minCCT = this.state.config.decodedAttributes.groups.find((group) => {
            return group.attributes[0].readableValue === this.state.config.minColorTemp;
        })
        if (!minCCT) {
            this.state.config.addGroup();
            let groups = this.state.config.decodedAttributes.groups;
            groups[groups.length - 1].attributes[0].setReadableValue(this.state.config.minColorTemp);
        }
    }

    addMidCCT() {
        if (!this.state.config.pagesConfig[2].visible) {
            return;
        }
        let midCCT = this.state.config.decodedAttributes.groups.find((group) => {
            return group.attributes[0].readableValue === this.state.config.midColorTemp;
        })
        if (!midCCT) {
            this.state.config.addGroup();
            let groups = this.state.config.decodedAttributes.groups;
            groups[groups.length - 1].attributes[0].setReadableValue(this.state.config.midColorTemp);
        }
    }

    addMaxCCT() {
        let maxCCT = this.state.config.decodedAttributes.groups.find((group) => {
            return group.attributes[0].readableValue === this.state.config.maxColorTemp;
        })
        if (!maxCCT) {
            this.state.config.addGroup();
            let groups = this.state.config.decodedAttributes.groups;
            groups[groups.length - 1].attributes[0].setReadableValue(this.state.config.maxColorTemp);
        }
    }

    useConfigAttr() {
        if ((typeof this.props.configAttribute !== "undefined") && this.props.configAttribute !== 'undefined') {
            return this.props.configAttribute;
        }
        return '01'
    }
}
