import React from 'react';
import { List } from 'semantic-ui-react';
import ReactTooltip from 'react-tooltip';
import aferoLogo from '../../images/afero-inspector-logo.svg';

export default function SidebarComponent({ handle_myColor, handle_selectDeviceId, userState }) {
  let currentComponent = localStorage.getItem('currentComponent');
  return (
    <div className='sidebar-container'>
      <div id='sidebarContent-item'>
        <div id='naviMainAferoLogo'>
          <a href='/home'>
            <img src={aferoLogo} alt='Afero, Inc.' />
          </a>
        </div>
        <List id='listOfDevices' divided relaxed verticalAlign='middle' size='large'>
          {userState.devDetails.map((dev, i) => {
            return (
              <List.Item
                key={dev.deviceId}
                id='deviceListPadding'
                data-deviceid={dev.deviceId}
                data-friendlyname={dev.friendlyName}
                data-direct={dev.direct}
                style={{
                  background: currentComponent !== 'accountDetailsComponent' ? handle_myColor(dev.deviceId) : '#313131'
                }}
                onClick={event => {
                  handle_selectDeviceId(event);
                }}
              >
                {dev.available === true ? (
                  <List.Icon
                    name='circle'
                    size='tiny'
                    style={{ color: '#ffac3e' }}
                    verticalAlign='middle'
                    data-tip
                    data-for={dev.deviceId + 'deviceOnlineTip'}
                  >
                    <ReactTooltip id={dev.deviceId + 'deviceOnlineTip'} type='light' effect='solid' place='top'>
                      <span>Online</span>
                    </ReactTooltip>
                  </List.Icon>
                ) : (
                  <List.Icon
                    name='circle'
                    size='tiny'
                    color='grey'
                    verticalAlign='middle'
                    data-tip
                    data-for={dev.deviceId + 'deviceOfflineTip'}
                  >
                    <ReactTooltip id={dev.deviceId + 'deviceOfflineTip'} type='light' effect='solid' place='top'>
                      <span>Offline</span>
                    </ReactTooltip>
                  </List.Icon>
                )}
                <List.Content>{dev.friendlyName}</List.Content>
              </List.Item>
            );
          })}
        </List>
      </div>
    </div>
  );
}
