import {cloneDeep} from 'lodash' ;

class ConfigDecode {
    constructor(configValue, attributes) {
       this._setBaseValues(configValue, attributes);
    }

    _setBaseValues(configValue, attributes) {
        this.configValue = configValue;
        this.splitConfigValue = !!configValue.match(/(..?)/g)?configValue.match(/(..?)/g):[];
        this.attributes = cloneDeep(attributes);
    }

    _buildConfigAttributeValues(attribute, arrayStartIndex) {
        let buildAttr = cloneDeep(attribute);
        this.#setRawConfigAttribute(buildAttr, arrayStartIndex);
        this.#setReadableConfigValue(buildAttr);
        return buildAttr;
    }

    #setRawConfigAttribute(attribute, arrayStartIndex) {
        if (componentAttributeUsesMultipleBytes(attribute)) {
            attribute.rawValue = [this.splitConfigValue[arrayStartIndex]];
            arrayStartIndex++;
            attribute.rawValue.push(this.splitConfigValue[arrayStartIndex]);
        } else {
            if(this.splitConfigValue[arrayStartIndex]) {
                attribute.rawValue = this.splitConfigValue[arrayStartIndex];
            } else{
                attribute.rawValue = '00';
            }
        }

        function componentAttributeUsesMultipleBytes(attribute) {
            return attribute.numOfAttributesFromComponent > 1;
        }
    }

    #setReadableConfigValue(attribute) {
        if (attribute.decode  && attribute.possibleValues) {
            attribute.readableValue = attribute.decode(attribute.rawValue, attribute.possibleValues);
        } else {
            attribute.readableValue = (attribute.decode) ?
                attribute.decode(attribute.rawValue) : '';
        }
    }
}
export default ConfigDecode
