import { compose, lifecycle } from 'react-recompose';
import { connect } from 'react-redux';
import withRouter from '../../components/nav/with-router';
import MainPage from '../../components/main/MainPage';

import clearDevLogsProcess from '../thunks/clearDevLogsProcess';
import clearReducerAndStorageProcess from '../thunks/clearReducerAndStorageProcess';
import defaultDevLogsProcess from '../thunks/defaultDevLogsProcess';
import defaultConnectionLogsProcess from '../thunks/defaultConnectionLogsProcess';
import defaultUpdatedErrorMsgProcess from '../thunks/defaultUpdatedErrorMsgProcess';
import flag_repullApiProcess from '../thunks/flag_repullApiProcess';
import flag_getAferoAttributesProcess from '../thunks/flag_getAferoAttributesProcess';
import flag_getDevAttributesProcess from '../thunks/flag_getDevAttributesProcess';
import flag_displayCurrentConnectionProcess from '../thunks/flag_displayCurrentConnectionProcess';
import flag_userStateProcess from '../thunks/flag_userStateProcess';
import getAferoAttributesProcess from '../thunks/getAferoAttributesProcess';
import getDevProfileProcess from '../thunks/getDevProfileProcess';
import getDevAttributesProcess from '../thunks/getDevAttributesProcess';
import getUserStateProcess from '../thunks/getUserStateProcess';
import storeCurrentComponentProcess from '../thunks/storeCurrentComponentProcess';
import {handleKeycloakRefreshPage} from "../../api/authentication";
import storeAccountDevices from "../thunks/storeAccountDevices";
import sortAttributesProcess from '../thunks/sortAttributesProcess';
import updateAttributesProcess from '../thunks/updateAttributesProcess';
import monitorConclaveProcess from "../thunks/monitorConclaveProcess";
import {closeWebSocket} from "../thunks/monitorConclaveProcess";
import {updateFTMDeviceAttribute}  from  "../thunks/updateAttributesProcess"
function mapStateToProps(state, ownProps) {
  return {
    aferoAttribute: state.aferoAttribute,
    connectionLogs: state.connectionLogs,
    deviceAttribute: state.deviceAttribute,
    deviceLogs: state.deviceLogs,
    deviceProfile: state.deviceProfile,
    getAferoAttributesFlag: state.getAferoAttributesFlag,
    getDeviceAttributesFlag: state.getDeviceAttributesFlag,
    getUserStateFlag: state.getUserStateFlag,
    repullApiFlag: state.repullApiFlag,
    displayCurrentConnectionFlag: state.displayCurrentConnectionFlag,
    userState: state.userState,
    updateAferoAttributeFlag: state.updateAferoAttributeFlag,
    updateDeviceAttributeFlag: state.updateDeviceAttributeFlag,
    ws: state.ws
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    clear_deviceLogs: () => {
      dispatch(clearDevLogsProcess());
    },
    clear_rootReducer: () => {
      dispatch(clearReducerAndStorageProcess());
    },
    default_connectionLogs: router => {
      dispatch(defaultConnectionLogsProcess(router));
    },
    default_devLogs: router => {
      dispatch(defaultDevLogsProcess(router));
    },
    default_updatedErrorMsg: (attrType, attrId) => {
      dispatch(defaultUpdatedErrorMsgProcess(attrType, attrId));
    },
    flag_aferoAttributes: boolean => {
      dispatch(flag_getAferoAttributesProcess(boolean));
    },
    flag_deviceAttributes: boolean => {
      dispatch(flag_getDevAttributesProcess(boolean));
    },
    flag_repullApi: boolean => {
      dispatch(flag_repullApiProcess(boolean));
    },
    flag_displayCurrentConnection: boolean => {
      dispatch(flag_displayCurrentConnectionProcess(boolean));
    },
    flag_userState: boolean => {
      dispatch(flag_userStateProcess(boolean));
    },
    get_aferoAttributes: (router, devProfile) => {
      dispatch(getAferoAttributesProcess(router, devProfile));
    },
    get_deviceAttributes: (router, devProfile) => {
      dispatch(getDevAttributesProcess(router, devProfile));
    },
    findAccountDevice: async router => {
      await storeAccountDevices(router, dispatch);
    },
    get_deviceProfile: async router => {
      return await dispatch(getDevProfileProcess(router));
    },
    get_userState: router => {
      dispatch(getUserStateProcess(router));
    },
    store_currentComponent: component => {
      dispatch(storeCurrentComponentProcess(component));
    },
    sort_attributes: (column, sortArrow, attrType) => {
      dispatch(sortAttributesProcess(column, sortArrow, attrType));
    },
    update_attributes: (payload, inputObj, router) => {
      dispatch(updateAttributesProcess(payload, inputObj, router));
    },
    monitor_conclave: (router) => {
      dispatch(monitorConclaveProcess(router))
    },
    closeWs: () => {
      dispatch(closeWebSocket());
    },
    update_device_attributes: (attributeType,accountId,deviceId) =>{
      dispatch(updateFTMDeviceAttribute(attributeType,accountId,deviceId))
    }
  };
}

const withlifecycle = lifecycle({

  componentDidMount() {
    this.props.store_currentComponent('mainComponent');
    //to handle displayCurrentConnectionFlag when user press refresh
    let { direct } = JSON.parse(localStorage.getItem('selectedDeviceObj'));
    if (direct === 'false') {
      this.props.flag_displayCurrentConnection(false);
    } else if (direct === 'true') {
      this.props.flag_displayCurrentConnection(true);
    }
    let { router } = this.props;
    handleKeycloakRefreshPage(() => {
      this.props.findAccountDevice(router).then(x=> {
        this.props.get_deviceProfile(router).then(devProfile=> {
            this.props.get_aferoAttributes(router, devProfile);
            this.props.get_deviceAttributes(router, devProfile);
        });
        this.props.default_connectionLogs(router);
        this.props.get_userState(router);
        this.props.monitor_conclave(router);
      });
      this.props.default_devLogs(router);
    });
  },

  /*LifeCycle: Growth/Update*/
  componentDidUpdate(prevProps, prevState) {
    let { flag_repullApi, router, repullApiFlag } = this.props;

    if (repullApiFlag === true) {
      /*when user clicks different deviceId it is considered different rounting
      path, so monitor on componentDidMount will no longer work cause we are
      now in the Growth/Update phrase, and thus, this act as a second monitor*/
      this.props.findAccountDevice(router).then(x=> {
        this.props.get_deviceProfile(router).then(devProfile=> {
          this.props.get_aferoAttributes(router, devProfile);
          this.props.get_deviceAttributes(router, devProfile);
        });
        this.props.get_userState(router);
      });
      flag_repullApi(false);
    }
  },

  /*LifeCycle: Death/Unmount*/
  componentWillUnmount() {
    this.props.closeWs();
    this.props.flag_userState(false);
    this.props.flag_aferoAttributes(false);
    this.props.flag_deviceAttributes(false);
  }
});

const connectToStore = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default compose(
  connectToStore,
  withlifecycle
)(withRouter(MainPage));
