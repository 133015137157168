import React from "react";
import { useKeycloak } from "@react-keycloak/web";
import {Button} from "semantic-ui-react";

const KeycloakButton = () => {
    const { keycloak } = useKeycloak();
    return (
        <div>
            {!keycloak.authenticated && (
                <Button id='signinButtonColor'
                        fluid size='massive'
                        onClick={() => keycloak.login()}>
                    Login
                </Button>
            )}
            {!!keycloak.authenticated && (
                <Button id='signinButtonColor'
                        fluid size='massive'
                        onClick={() => keycloak.logout()}>
                    Logout
                </Button>
            )}
        </div>
    );
};

export default KeycloakButton;
