let whenVersionIsChosen = (me, pages) => {
    me.editable = true;
    let controlled = getQuestionAnswer(pages[0],0)
    let brightness = getQuestionAnswer(pages[1],0)
    if(controlled && controlled.version) {
        me.setReadableValue(controlled.version);
        setMidColorTempToVisible(me, pages);
    } else if(controlled && brightness) {
        me.setReadableValue(brightness.version);
        setMidColorTempToVisible(me, pages);
    }
}

let setMidColorTempToVisible = (me, pages) => {
    let controlled = getQuestionAnswer(pages[0],0)
    pages[2].questions[1].config.visible = !!(controlled && controlled.key === '3 separate white channels');
}

let getQuestionAnswer = (page, question) => {
    return page.questions[question].answer
}

export {whenVersionIsChosen}
