// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../images/error404.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".error-404-container .error-404-img-container {\n  width: 50%;\n  height: 100vh;\n  float: left;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: cover;\n  background-position: 10%;\n}\n.error-404-container .error-404-text-container {\n  width: 50%;\n  height: 100vh;\n  float: left;\n  background: #454b4e;\n}\n.error-404-container .text-container {\n  color: white;\n  text-align: center;\n}\n.error-404-container .text-container #error404Afero-logo {\n  margin-top: 40px;\n  width: 160px;\n}\n.error-404-container .text-container #error404H1 {\n  margin-top: 80px;\n  font-family: \"Roboto\", sans-serif;\n  font-size: 100px;\n  font-weight: 300;\n}\n.error-404-container .text-container #error404H2 {\n  margin-top: 30px;\n  font-family: \"Roboto\", sans-serif;\n  font-size: 30px;\n  font-weight: 300;\n}\n.error-404-container .text-container p {\n  margin-top: 40px;\n}\n.error-404-container .text-container p a {\n  font-family: \"Roboto\", sans-serif;\n  font-size: 18px;\n  color: white;\n  text-decoration: underline;\n}\n\n/*# sourceMappingURL=error404.css.map */\n", "",{"version":3,"sources":["webpack://./src/scss/error404.scss","webpack://./src/css/out/error404.css"],"names":[],"mappings":"AACE;EACE,UAAA;EACA,aAAA;EACA,WAAA;EACA,mDAAA;EACA,sBAAA;EACA,wBAAA;ACAJ;ADGE;EACE,UAAA;EACA,aAAA;EACA,WAAA;EACA,mBAAA;ACDJ;ADIE;EACE,YAAA;EACA,kBAAA;ACFJ;ADII;EACE,gBAAA;EACA,YAAA;ACFN;ADKI;EACE,gBAAA;EACA,iCAAA;EACA,gBAAA;EACA,gBAAA;ACHN;ADMI;EACE,gBAAA;EACA,iCAAA;EACA,eAAA;EACA,gBAAA;ACJN;ADOI;EACE,gBAAA;ACLN;ADMM;EACE,iCAAA;EACA,eAAA;EACA,YAAA;EACA,0BAAA;ACJR;;AAEA,uCAAuC","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
