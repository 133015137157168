import env from '../../env.js';
import * as auth from '../../api/authentication';
import keycloak from "../../keycloak";
import {signOutPromise} from "../../api/authentication";

/*handle user's login credential and its error messages*/

export default function signinProcess(userObj) {
  function dispatchLoginError(error, dispatch) {
    if (!error) {
      console.error('Unknown Login Error');
    }
    if (error.code === 400) {
      dispatch({type: 'SIGNIN_ERROR_MSG', signinErrorMsg: 'Invalid credentials'});
    } else if (error.code === 403) {
      dispatch({type: 'SIGNIN_ERROR_MSG', signinErrorMsg: 'You are not authorized to access this resource'});
    } else {
      dispatch({
        type: 'SIGNIN_ERROR_MSG',
        signinErrorMsg: 'There was an error accessing the requested resource: ' + error.message
      });
    }
  }

  async function onAuthSuccess(dispatch) {
    try {
      await auth.signInPromise();
      if (await auth.handleAuthentication(userObj.router, dispatch) === false) {
        return;
      }

      localStorage.setItem('selectedEnv', env.cloudEnv);
      localStorage.setItem('currentComponent', 'homeComponent');

      userObj.router.navigate('/home');
    } catch (error) {
      dispatchLoginError(error, dispatch);
    }
  }

  async function onAuthLogout(dispatch) {
    try {
      signOutPromise(dispatch);
    } catch (error) {
      dispatchLoginError(error, dispatch);
    }
  }

  return async (dispatch, getState) => {
    localStorage.setItem('kc_token', keycloak.token);
    localStorage.setItem('kc_refreshToken', keycloak.refreshToken);

    if (userObj.error){
      return;
    }

    switch (userObj.event) {
      case 'onAuthSuccess':
        await onAuthSuccess(dispatch);
        break;
      case 'onAuthLogout':
        await onAuthLogout(dispatch);
        break;
      default:
        break;
    }
  };
}
