import {updateDeviceAttributeUi} from "./updateAttributesProcess";
import storeAccountDevices from "./storeAccountDevices";
import defaultConnectionLogsProcess from "./defaultConnectionLogsProcess";
import {getConclaveAccess} from "../../api/http_request_wrapper";
import defaultDevLogsProcess from "./defaultDevLogsProcess";
import format from "date-fns/format";
import parse from "date-fns/parse";
import {Action} from "../reducers/rootReducer";

const DEV_LOG_DISCONNECTED = 'Connection to device logs closed. Refresh the page to reconnect.';
const DEV_LOG_ERROR = 'Error occurred connecting to device logs. Refresh the page to try again.';

export function conclaveDevLogsProcess(response) {
    return async (dispatch, getState, router) => {
        try {
            let data = JSON.parse(response);
            if (data.private?.data?.id && data.private.event === 'attr_change') {
                attributeChanged(dispatch, getState, data);
            } else if (data.private?.data?.id && data.private.event === 'status_change' && getState().deviceLogs[data.private.data.id]) {
                statusChanged(dispatch, getState, router, data);
            } else if (data.type === 'error') {
                dispatch({type: Action.UPDATE_DEVICE_LOGS, deviceLogs: DEV_LOG_ERROR, deviceId: data.deviceId});
            } else if (data.type === 'closed' && data.code !== 1000) {
                dispatch({type: Action.UPDATE_DEVICE_LOGS, deviceLogs: DEV_LOG_DISCONNECTED, deviceId: data.deviceId});
            }

        } catch (error) {
            console.log(error);
        }
    };
}

function attributeChanged(dispatch, getState, data) {
    let attribute = data.private.data.attribute;
    let message = formatUpdateAttributeLog(attribute, getState);
    dispatch({type: Action.UPDATE_DEVICE_LOGS, deviceLogs: message, deviceId: data.private.data.id});
    if (getState().deviceAttribute.deviceId === data.private.data.id) {
        updateDeviceAttributeUi((attribute.id>1000?'aferoAttribute':'deviceAttribute'),attribute.id, attribute.value)(dispatch, getState);
    }
}

function statusChanged(dispatch, getState, router, data) {
    let status = data.private.data.status;
    let message = formatUpdateConnectionLog(status);
    dispatch({type: Action.UPDATE_DEVICE_LOGS, deviceLogs: message, deviceId: data.private.data.id});
    if (getState().deviceAttribute.deviceId === data.private.data.id) {
        updateConnectionUi(status, data.private.data.id, router)(dispatch, getState);
    }
}

function formatUpdateAttributeLog(attribute, getState) {
    let existingAttr = getState().deviceAttribute.attributes.find(attr => attr.attributeId === attribute.id)
    let timestamp = format(parse(attribute.updatedTimestamp).toString(), 'h:mm A')
    let attributeReadable = (existingAttr?.semanticType)?`(${existingAttr.semanticType})`:'';
    let message = `${timestamp}- Attribute ${attribute.id}${attributeReadable} changed to ${attribute.value}`;
    return message
}

function formatUpdateConnectionLog(status) {
    let timestamp = format(parse(status.updatedTimestamp).toString(), 'h:mm A')

    let message = `${timestamp}- Device Connection: ${not(status.available)}available`+
        `, ${not(status.connected)}connected, ${not(status.connectable)}connectable` +
        `, ${not(status.linked)}linked, ${not(status.visible)}visible`;
    return message;
}

function not(status) {
    if(status) return '';
    return 'not ';
}

export function updateConnectionUi(connection, deviceId, router) {
    return async (dispatch, getState) => {
        getState().deviceAttribute.attributes.forEach(
            attr => {
                attr.available = connection.available;
            }
        );
        let device= getState().userState.devDetails.find(dev => dev.deviceId === deviceId);
        device.direct = connection.direct;
        device.available = connection.available;
        await storeAccountDevices(router, dispatch)
        defaultConnectionLogsProcess(router)(dispatch, getState)
    }
}

export default function monitorConclaveProcess(router) {
    return async (dispatch, getState) => {
        const accountId = localStorage.getItem('accountId')
        await defaultDevLogsProcess(router)(dispatch);

        getConclaveAccess(accountId).then((response) => {
            let host = response.conclaveHosts.find(host => host.type === 'socket');
            let wsUri;
            let port;
            if (window.location.protocol === "https:") {
                wsUri = "wss://";
                port = window.location.port;
            } else {
                wsUri = "ws://";
                port = ":3001";
            }
            let ws = new WebSocket(wsUri + window.location.hostname + port + "/websocket");
            getState().ws = ws;
            ws.onerror = function error(err) {
                console.log('error displayed',err)
                conclaveDevLogsProcess(JSON.stringify({type:'error', deviceId: getState().deviceAttribute.deviceId}))(dispatch, getState, router);
                console.log('Socket encountered error: ', err, 'Closing socket');
            };

            ws.onopen = function open() {
                console.log('open device log connection' )
                ws.send(JSON.stringify({account: accountId, token: response.tokens[0].token, host: host.host, port: host.port }));
            };

            ws.onclose = function close(event) {
                console.log('close device logs connection')
                conclaveDevLogsProcess(JSON.stringify(
                    {type: 'closed', deviceId: getState().deviceAttribute.deviceId, code: event.code}))(dispatch, getState, router);
            }

            ws.onmessage = function message(data) {
                if (data.data.includes('Error')) {
                    console.log('Error from server: ', data.data)
                    conclaveDevLogsProcess(JSON.stringify({type: 'error', message: data.data, deviceId: getState().deviceAttribute.deviceId}))(dispatch, getState, router);
                } else {
                    conclaveDevLogsProcess(data.data)(dispatch, getState, router);
                }
            };
        })
    }
}


export function closeWebSocket() {
    return async (dispatch, getState) => {
        if (getState().ws) {
            console.log('Closing socket');
            getState().ws.close();
        }
    }
}
