import * as auth from '../../api/authentication';
import {getAccountDevices} from "../../api/http_request_wrapper";

/*each device will have a default log of empty array, and contents will be push into
the empty array by one of the middlewares*/

export default function defaultDevLogsProcess(router) {
  return async (dispatch, getState) => {
    try {
      const accountId = localStorage.getItem('accountId');
      await auth.handleAuthentication(router, dispatch);

      let devDetails = await getAccountDevices(accountId, 'profile');
      let devLogsDefaultScope = {};

      devDetails.forEach(dev => {
        //NOTE profile.presentation excludes all hubs
        if (dev.profile.presentation) {
          devLogsDefaultScope[dev.deviceId] = {
            logs: []
          };
        }
      });
      dispatch({ type: 'DEFAULT_DEVICE_LOGS', deviceLogs: devLogsDefaultScope });
    } catch (error) {
      console.log(error);
    }
  };
}
