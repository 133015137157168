import React from 'react';
import aferoLogo from '../../images/afero-inspector-logo.svg';

export default function Error404Component() {
  return (
    <div className='error-404-container'>
      <div className='error-404-img-container' />
      <div className='error-404-text-container'>
        <div className='text-container'>
          <img alt='404page' id='error404Afero-logo' src={aferoLogo} />
          <h1 id='error404H1'>404</h1>
          <h2 id='error404H2'>
            Whoops...you are looking <br /> in the wrong place!
          </h2>
          <p>
            <a id='goHome404Error' href='/home'>
              Go to home page
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
