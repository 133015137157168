import {MODULE_GEN1, MODULE_GEN2A, MODULE_GEN2B, MODULE_SMALLFFV1,MODULE_SMALLFFV2, VERTICAL, HORIZONTAL} from "./WizardPages";

const ALL = [{key: 'n/a', value: 255, hidden: true},
    {key:'14', value: 14}, {key:'26', value: 26},
    {key:'13', value: 13}, {key:'15', value: 15},
    {key:'4', value: 4}, {key:'1', value: 1},
    {key:'18', value: 18}, {key:'21', value: 21},
    {key:'27', value: 27}, {key:'25', value: 25},
    {key: '17', value: 17}, {key:'20', value: 20},
    {key: '3', value: 3}, {key:'2', value: 2},
    {key: '35', value: 35}, {key:'36', value: 36},
    {key: '0', value: 0}, {key: '0', value: 22}
];

const GEN1 = (mount, showAll) => [{ key: 'n/a', value: 255, hidden: true },
{ key: 'PWM1/IO0', value: 14, pin: 5 }, { key: 'PWM2/IO1', value: 26, pin: 7 },
{ key: 'PWM3/IO2', value: 13, pin: 9 }, { key: 'PWM4/IO3', value: 15, pin: 11 },
{ key: 'PWM5', value: 4, pin: 13 },
{ key: 'IO6', value: 23, pin: mount === VERTICAL ? 12 : 20 },
{ key: 'IO7', value: 18, pin: 6, hidden: (mount === VERTICAL) },
{ key: 'IO8', value: 21, pin: 19, hidden: (mount === VERTICAL) },
{ key: 'TX1', value: 27, pin: 8, hidden: !showAll },
{ key: 'RX1', value: 25, pin: mount === VERTICAL ? 6 : 10, hidden: !showAll }
];

const ESP32 = (mount) => [{ key: 'n/a', value: 255, pin: null, hidden: true },
{ key: 'PWM1/IO0', value: 14, pin: 1 }, { key: 'PWM2/IO1', value: 26, pin: 3 },
{ key: 'PWM3/IO2', value: 13, pin: 5 }, { key: 'PWM4/IO3', value: 15, pin: 7 },
{ key: 'PWM5/IO4', value: 4, pin: 9 }, { key: 'PWM6/IO5', value: 18, pin: 11 },
{ key: 'IO8', value: 21, pin: 22, hidden: (mount === VERTICAL) }, { key: 'TX1/SDA/IO11', value: 27, pin: mount === VERTICAL ? 14 : 20 },
{ key: 'RX1/SCL/IO12', value: 25, pin: mount === VERTICAL ? 16 : 18 }, { key: 'ADC0/IO6', value: 36, pin: mount === VERTICAL ? 12 : 26 },
{ key: 'IO9', value: 22, pin: 28, hidden: (mount === VERTICAL) }, { key: 'ADC2/IO10', value: 35, pin: 30, hidden: (mount === VERTICAL) },
]

const AmebaZ2 = (mount) => [{ key: 'n/a', value: 255, hidden: true },
{ key: 'PWM1/IO0', value: 14, pin: 1 },
{ key: 'PWM2/IO1', value: 13, pin: 3 },
{ key: 'PWM3/IO2', value: 4, pin: 5 },
{ key: 'PWM4/IO3', value: 17, pin: 7 },
{ key: 'PWM5/IO4', value: 18, pin: 9 },
{ key: 'PWM6/IO5', value: 20, pin: 11 },
{ key: 'IO8', value: 1, pin: 22, hidden: (mount === VERTICAL) },
{ key: 'TX1/SDA/IO11', value: 3, pin: mount === VERTICAL ? 14 : 20 },
{ key: 'RX1/SCL/IO12', value: 2, pin: mount === VERTICAL ? 16 : 18 },
{ key: 'IO10', value: 0, pin: 30, hidden: (mount === VERTICAL) },
]

const SmallFFV1 = (mount) => [{ key: 'n/a', value: 255, hidden: true },
{ key: 'PWM1', value: 14, pin: 3, hidden: (mount === HORIZONTAL) },
{ key: 'PWM2', value: 26, pin: 5, hidden: (mount === HORIZONTAL) },
{ key: 'PWM3', value: 13, pin: 4, hidden: (mount === HORIZONTAL) },
]

const SmallFFV2 = (mount) => [{ key: 'n/a', value: 255, hidden: true },
{ key: 'PWM1', value: 14, pin: 3, hidden: (mount === HORIZONTAL) },
{ key: 'PWM4', value: 15, pin: 4, hidden: (mount === HORIZONTAL) },
{ key: 'IO26', value: 26, pin: 5, hidden: (mount === HORIZONTAL) },
]
const McuI2CSDASCLGEN2BGpio = (mount, isSDA) => [{ key: 'n/a', value: 255, hidden: true },
{ key: 'TX1/SDA/IO11', value: 3, pin: mount === VERTICAL ? 14 : 20 },
{ key: 'RX1/SCL/IO12', value: 2, pin: mount === VERTICAL ? 16 : 18 },
]


let getPossibleGpios = (mount, module, showAll = false, isI2CGpio = false,isSDA=false) => {
    if (module === MODULE_GEN1) {
        return GEN1(mount, showAll);
    } else if (module === MODULE_GEN2A) {
        return ESP32(mount);
    } else if (module === MODULE_GEN2B) {
        if(isI2CGpio){
            return McuI2CSDASCLGEN2BGpio(mount,isSDA);
        }
        else{
            return AmebaZ2(mount);
        }        
    } else if (module === MODULE_SMALLFFV1) {
         return SmallFFV1(mount);
    } else if (module === MODULE_SMALLFFV2 ) {
         return SmallFFV2(mount);
    } else {
        return ALL;
    }
}
export {getPossibleGpios, ALL}
