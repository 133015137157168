import React, { Component } from 'react';
import { Table } from 'semantic-ui-react';

import FooterComponent from '../main/FooterComponent';
import HeaderAccountComponent from './HeaderAccountComponent';
import LoaderComponent from '../miscellaneous/LoaderComponent';
import SidebarComponent from '../main/SidebarComponent';
import keycloak from "../../keycloak";

export default class AccountComponent extends Component {
  constructor(props) {
    super(props);
    this.props.store_currentComponent('accountDetailsComponent');
  }
  /*store deviceId to localStorage and toggle rssiGraph*/
  handle_selectDeviceId = event => {
    event.preventDefault();
    let { router } = this.props;

    let obj = {
      deviceId: event.currentTarget.dataset.deviceid,
      friendlyName: event.currentTarget.dataset.friendlyname,
      direct: event.currentTarget.dataset.direct
    };

    //user's selected deviceId will be stored in localStorage
    localStorage.setItem('selectedDeviceObj', JSON.stringify(obj));
    router.navigate(`/main/${obj.deviceId}`);
  };

  /*reroute to Account Component*/
  handle_routeAccountDetails = event => {
    event.preventDefault();
    let { flag_displayCurrentConnection, router } = this.props;

    flag_displayCurrentConnection(false);
    router.navigate(`/account`);
  };

  /*reroute to Home Component*/
  handle_routeHome = event => {
    event.preventDefault();
    let { flag_displayCurrentConnection, router } = this.props;

    flag_displayCurrentConnection(false);
    router.navigate(`/home`);
  };

  /*reroute to Signout Component*/
  handle_routeSignOut = event => {
    event.preventDefault();
    let { clear_rootReducer, router } = this.props;

    clear_rootReducer();
    router.navigate(`/`);
    keycloak.logout()
  };

  render() {
    let { userState, getUserStateFlag } = this.props;
    return (
      <div>
        {!getUserStateFlag ? (
          <LoaderComponent />
        ) : (
          <div className='account-container'>
            <div id='headerAccount-item'>
              <HeaderAccountComponent
                handle_routeAccountDetails={this.handle_routeAccountDetails}
                handle_routeHome={this.handle_routeHome}
                handle_routeSignOut={this.handle_routeSignOut}
              />
            </div>
            <div id='sideBarAccount-item'>
              <SidebarComponent userState={userState} handle_selectDeviceId={this.handle_selectDeviceId} />
            </div>
            <div id='accountDetails-item'>
              <div className='deviceDetails-container'>
                <div id='deviceUserName-item'>
                  <h2>USER</h2>
                  <Table compact='very' basic='very'>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell className='width30'>Name</Table.Cell>
                        <Table.Cell className='width70'>{userState.firstName + ' ' + userState.lastName}</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell className='width30'>User ID</Table.Cell>
                        <Table.Cell className='width70'>{userState.userId}</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell className='width30'>Username (email)</Table.Cell>
                        <Table.Cell className='width70'>{userState.credentialId}</Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>

                <div id='deviceAccount-item'>
                  <h2>ACCOUNTS</h2>
                  {userState.accounts.map((obj, i) => {
                    return (
                      <Table key={i} compact='very' basic='very'>
                        <Table.Body>
                          <Table.Row verticalAlign='top'>
                            <Table.Cell className='width30'>{obj.description + ' ID'}</Table.Cell>
                            <Table.Cell className='width70'>{obj.accountId}</Table.Cell>
                          </Table.Row>
                          <Table.Row verticalAlign='top'>
                            <Table.Cell className='width30'>{'Type '}</Table.Cell>
                            <Table.Cell className='width70'>{obj.type}</Table.Cell>
                          </Table.Row>
                        </Table.Body>
                      </Table>
                    );
                  })}
                </div>

                <div id='devicePartner-item'>
                  <h2>PARTNERS</h2>
                  {userState.partnerAccess.map((obj, i) => {
                    return (
                      <Table key={i} compact='very' basic='very'>
                        <Table.Body>
                          <Table.Row>
                            <Table.Cell className='width30'>{'Partner Name '}</Table.Cell>
                            <Table.Cell className='width70'>{obj.partnerName}</Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell className='width30'>{'Partner ID '}</Table.Cell>
                            <Table.Cell className='width70'>{obj.partnerId}</Table.Cell>
                          </Table.Row>
                        </Table.Body>
                      </Table>
                    );
                  })}
                </div>
              </div>
            </div>
            <div id='footerAccount-item'>
              <FooterComponent />
            </div>
          </div>
        )}
      </div>
    );
  }
}
