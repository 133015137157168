/*clear out localStroage and rootReducer data when user logs out, and unmonitor+logout promise*/

export default function clearReducerAndStorageProcess() {
  return async (dispatch, getState) => {
    try {
      localStorage.removeItem('accountId');
      localStorage.removeItem('afCreds');
      localStorage.removeItem('allUserDevs');
      localStorage.removeItem('currentComponent');
      localStorage.removeItem('selectedDeviceObj');
      localStorage.removeItem('selectedEnv');
      localStorage.removeItem('kc_token');
      localStorage.removeItem('kc_refreshToken');
      localStorage.removeItem('accountDevices');

      dispatch({
        type: 'CLEAR_ROOT_REDUCER',
        aferoAttribute: {},
        connectionLogs: {},
        deviceAttribute: {},
        deviceProfile: {},
        deviceLogs: {},
        getAferoAttributesFlag: false,
        getDeviceAttributesFlag: false,
        getUserStateFlag: false,
        repullApiFlag: false,
        signinErrorMsg: '',
        displayCurrentConnectionFlag: false,
        updateAferoAttributeFlag: false,
        updateDeviceAttributeFlag: false,
        userState: {}
      });
    } catch (error) {
      console.log(error);
    }
  };
}
