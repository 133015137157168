import React, { Component } from 'react';
import { Provider } from 'react-redux';
import setupStore from './redux/setupStore';
import { BrowserRouter as Router,Route ,Routes} from 'react-router-dom';

import AccountContainer from './redux/containers/AccountContainer';
import Error404Component from './components/miscellaneous/Error404Component';
import HomeContainer from './redux/containers/HomeContainer';
//import MainContainer from './redux/containers/MainContainer';
import SigninContainer from './redux/containers/SigninContainer';
import Error401Component from "./components/miscellaneous/Error401Component";
import MainRouteComponent from "./components/nav/MainRouteComponent";

export const history = require("history").createBrowserHistory()

const store = setupStore();

export default class App extends Component {
  render() {
    return (
      <div className='App'>
        <Provider store={store}>
          <Router>
          <Routes>
          <Route  path='/' element={<SigninContainer />} />
              <Route
                path='/main/:deviceId'
                element={<MainRouteComponent />}
                // render={({ match }) => {
                //   //handle invalid url
                //   const paramDeviceId = match.params.deviceId;
                //   let allUserDevs = localStorage.getItem('allUserDevs') ? JSON.parse(localStorage.getItem('allUserDevs')) : null;
                //   let deviceObj = localStorage.getItem('selectedDeviceObj') ? true : null;
                //   let deviceAccess = allUserDevs && allUserDevs.includes(paramDeviceId);

                //   if (!deviceObj) {
                //     return <SigninContainer />;
                //   } else if (!deviceAccess) {
                //     return <Error404Component />;
                //   } else if (!allUserDevs) {
                //     return <Error404Component />;
                //   } else if (deviceAccess) {
                //     return <MainContainer history={history} />;
                //   } else {
                //     return <Link to='/' />;
                //   }
                // }}
              />
              <Route  path='/home' element={<HomeContainer />} />
              <Route  path='/account' element={<AccountContainer />} />
              <Route  path='/error' element={<Error401Component />} />
              <Route element={<Error404Component />} />
          </Routes>
          </Router>
        </Provider>
      </div>
    );
  }
}
