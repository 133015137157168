import keycloak from "../keycloak";
import env from '../env.js';

const clientApiUrl = env.clientApiUrl;
const clientSecret = env.clientSecret;
const clientId = env.clientId;

async function getUserDetails() {
    let me = await fetch(clientApiUrl + "/v1/users/me",{
        headers:{
            'clientSecret': clientSecret,
            'clientId': clientId,
            'Access-Control-Request-Method': 'GET',
            'Access-Control-Request-Headers': 'authorization',
            'Authorization': 'Bearer '+ keycloak.token
        }});
    return await me.json();
}

async function getAccountDevices(accountId, expansions) {
    let queryParams = new URLSearchParams({expansions:expansions})
    let accountDevices = await fetch(clientApiUrl + "/v1/accounts/" + accountId + "/devices?" + queryParams, {
        headers:{
            'clientSecret': clientSecret,
            'clientId': clientId,
            'Access-Control-Request-Method': 'GET',
            'Access-Control-Request-Headers': 'authorization',
            'Authorization': 'Bearer '+ keycloak.token
        }});
    return await accountDevices.json();
}

async function getDeviceProfile(accountId, deviceId) {
    let deviceProfile = await fetch(
        clientApiUrl + "/v1/accounts/" + accountId + "/devices/" + deviceId + "/deviceProfile", {
            headers:{
            'clientSecret': clientSecret,
            'clientId': clientId,
            'Access-Control-Request-Method': 'GET',
            'Access-Control-Request-Headers': 'authorization',
            'Authorization': 'Bearer '+ keycloak.token
        }});
    return await deviceProfile.json();
}

async function updateDeviceActions(accountId, deviceId, payload) {
    let deviceActionResponse = await fetch(
        clientApiUrl + "/v1/accounts/" + accountId + "/devices/" + deviceId + "/actions", {
            headers:{
                'Content-Type': 'application/json',
                'clientSecret': clientSecret,
                'clientId': clientId,
                'Access-Control-Request-Method': 'POST',
                'Access-Control-Request-Headers': 'authorization',
                'Authorization': 'Bearer '+ keycloak.token,
                'Accept': 'application/json'
            },
            body: JSON.stringify(payload),
            method: 'POST'
        });
    return await deviceActionResponse.json();
}

async function getConclaveAccess(accountId) {
    let loginResponse = await fetch(
        clientApiUrl + '/v1/accounts/' + accountId + '/conclaveAccess',
        {
            headers:{
                'Content-Type': 'application/json',
                'Access-Control-Request-Method': 'POST',
                'Access-Control-Request-Headers': 'authorization',
                'Authorization': 'Bearer ' + keycloak.token,
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                user:true,
                softHub:false
            }),
            method: 'POST'
        });
    return await loginResponse.json();
}

export { getUserDetails, getAccountDevices, getDeviceProfile, updateDeviceActions, getConclaveAccess};
