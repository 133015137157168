import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import './css/out/account.css';
import './css/out/attribute.css';
import './css/out/deviceInfo.css';
import './css/out/error404.css';
import './css/out/footerMain.css';
import './css/out/headerAccount.css';
import './css/out/headerHome.css';
import './css/out/headerMain.css';
import './css/out/home.css';
import './css/out/loader.css';
import './css/out/main.css';
import './css/out/visual.css';
import './css/out/sidebar.css';
import './css/out/signin.css';
import './css/out/decoder.css';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <App/>
);
