import Keycloak from "keycloak-js";
import {env} from './client-env'
const keycloak = new Keycloak({
    url: env.REACT_APP_KEYCLOAK_AUTH_URL,
    realm: encodeURIComponent(env.REACT_APP_KEYCLOAK_REALM),
    clientId: "inspector",
    redirectUri: encodeURIComponent(window.location.protocol + '//' + window.location.host + window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")) + '/home'),
    scope: encodeURIComponent('openid'),
    nonce: "mqp44"
});

export default keycloak;
