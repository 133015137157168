import React, { Component } from 'react';
import {
    Form, Icon,
    List,
    Message,
    Table
} from 'semantic-ui-react';
import { decodeColor } from "../../../util/encoder/decode_bulb_attributes";
import { encodeColor, encodeColorSet } from "../../../util/encoder/encode_bulb_attributes";
import Swatch from '@uiw/react-color-swatch';
import ClearSubmitComponent from "./ClearSubmitComponent";

export default class ColorDecoderComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            props: props,
            warnings: [],
            decodedColor: decodeColor(props.configAttribute),
            colorDefaults: [
                { name: 'YELLOW', red: 255, green: 255, blue: 0 },
                { name: 'MAGENTA/PINK', red: 255, green: 0, blue: 255 },
                { name: 'CYAN', red: 0, green: 255, blue: 255 },
                { red: 255, green: 136, blue: 0 },
                { red: 255, green: 0, blue: 136 },
                { red: 136, green: 255, blue: 0 },
                { red: 0, green: 255, blue: 136 },
                { red: 136, green: 0, blue: 255 },
                { red: 0, green: 136, blue: 255 },
                { red: 255, green: 255, blue: 136 },
                { red: 255, green: 136, blue: 255 },
                { red: 136, green: 255, blue: 255 },
                { red: 255, green: 136, blue: 136 },
                { red: 136, green: 255, blue: 136 },
                { red: 136, green: 136, blue: 255 }
            ],
            disableColorsTo: 12
        }
        let startingAttribute = props.configAttribute;
        if (startingAttribute.length < 12) {
            startingAttribute = '01';
        }
        this.state.decodedColor = decodeColor(startingAttribute);
        this.addRow(12);
        this.submit = this.submit.bind(this);
        this.validateInput = this.validateInput.bind(this);
        this.props.setInfoText('Lightbulb RGB Color Table. ' +
            'Configure the RGB Color Table attribute by setting the desired RGB and tuning values. ' +
            'Each value should be a value between 0 and 255.')
    }



    render() {
        return (
            <div>
                <Message warning hidden={this.state.warnings.length === 0}>
                    <Message.Header>
                        Warning
                    </Message.Header>
                    <Message.Content id='warnings'>
                        <List items={this.state.warnings} />
                    </Message.Content>
                </Message>
                <Form>
                    <div className="decoder-component" id='decoder_item'>
                        <div style={{ display: "flex", alignItems: 'flex-end' }}>
                            <div style={{ margin: '10px' }}>
                                Version {this.state.decodedColor.version}
                            </div>
                        </div>
                        <Table inverted celled compact='very' basic='very'>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell colSpan='2' />
                                    <Table.HeaderCell colSpan='3'>
                                        Color
                                    </Table.HeaderCell>
                                    <Table.HeaderCell colSpan='4'>
                                        Tuning
                                    </Table.HeaderCell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.HeaderCell>
                                        Name
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        Hex
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        Red
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        Green
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        Blue
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        Red
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        Green
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        Blue
                                    </Table.HeaderCell>
                                    <Table.HeaderCell />
                                    <Table.HeaderCell />
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {this.state.decodedColor.colorSets.map((colorSet, index) => {
                                    return (
                                        <Table.Row id={colorSet.hex} key={colorSet.hex + 'row' + index}>
                                            <Table.Cell>
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <Swatch key={'swatch' + index} colors={['#' + colorSet.hex]}
                                                        rectProps={{
                                                            style: {
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                            },

                                                        }} />
                                                    <span>
                                                        {(this.state.colorDefaults[index] && this.state.colorDefaults[index].name) ? this.state.colorDefaults[index].name : ('Custom '.concat(index - 2 + ''))}
                                                    </span>
                                                </div>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Form.Input key={'hex' + index} id='colorInput' defaultValue={'#'.concat(colorSet.hex)} data-column='hex' data-row={index} readOnly tabIndex={(index < this.state.disableColorsTo) ? '-1' : undefined}
                                                    onChange={(event) => this.validateInput(event)} onBlur={(event) => this.editColors(event, index, this.state.disableColorsTo)} style={{ width: '80px' }} />
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Form.Input key={'red' + index} id='colorInput' defaultValue={(colorSet.definition.red)} data-column='red' data-row={index} readOnly={index < this.state.disableColorsTo} tabIndex={(index < this.state.disableColorsTo) ? '-1' : undefined}
                                                    onChange={(event) => this.validateInput(event)} onBlur={(event) => this.editColors(event, index, this.state.disableColorsTo)}/>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Form.Input key={'green' + index} id='colorInput' defaultValue={(colorSet.definition.green)} data-column='green' data-row={index} readOnly={index < this.state.disableColorsTo} tabIndex={(index < this.state.disableColorsTo) ? '-1' : undefined}
                                                    onChange={(event) => this.validateInput(event)} onBlur={(event) => this.editColors(event, index, this.state.disableColorsTo)}/>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Form.Input key={'blue' + index} id='colorInput' defaultValue={(colorSet.definition.blue)} data-column='blue' data-row={index} readOnly={index < this.state.disableColorsTo} tabIndex={(index < this.state.disableColorsTo) ? '-1' : undefined}
                                                    onChange={(event) => this.validateInput(event)} onBlur={(event) => this.editColors(event, index, this.state.disableColorsTo)}/>
                                            </Table.Cell>
                                            <Table.Cell className='rgb-info'>
                                                <Form.Input key={'redt' + index} id='colorInput' defaultValue={(colorSet.tuning.red)} data-column='tuning' data-row={index}
                                                    onChange={(event) => this.validateInput(event, colorSet, 'redPercentage', 'red')} onBlur={(event) => this.editColors(event)} />
                                                <div className='percentage-section'>
                                                    <span>
                                                        {colorSet?.tuning?.redPercentage ? colorSet?.tuning?.redPercentage : this.calculatePercentage((colorSet.tuning.red))}
                                                    </span>
                                                </div>
                                            </Table.Cell>
                                            <Table.Cell className='rgb-info'>
                                                <Form.Input key={'greeent' + index} id='colorInput' defaultValue={(colorSet.tuning.green)} data-column='tuning' data-row={index}
                                                    onChange={(event) => this.validateInput(event, colorSet, 'greenPercentage', 'green')} onBlur={(event) => this.editColors(event)} />
                                                <div className='percentage-section'>
                                                    <span>
                                                        {colorSet?.tuning?.greenPercentage ? colorSet?.tuning?.greenPercentage : this.calculatePercentage((colorSet.tuning.green))}
                                                    </span>
                                                </div>
                                            </Table.Cell>
                                            <Table.Cell className='rgb-info'>
                                                <Form.Input key={'bluet' + index} id='colorInput' defaultValue={(colorSet.tuning.blue)} data-column='tuning' data-row={index}
                                                    onChange={(event) => this.validateInput(event, colorSet, 'bluePercentage', 'blue')} onBlur={(event) => this.editColors(event)} />
                                                <div className='percentage-section'>
                                                    <span>
                                                        {colorSet?.tuning?.bluePercentage ? colorSet?.tuning?.bluePercentage : this.calculatePercentage((colorSet.tuning.blue))}
                                                    </span>
                                                </div>
                                            </Table.Cell>
                                            <Table.Cell>
                                                {this.state.warnings.some((v) => v.includes('Error in row ' + (Number(index) + 1))) ?
                                                    <Icon name='sync' color={"red"} /> :
                                                    <Icon link name='sync' onClick={(event) => this.submitTuning(index, event)}
                                                        disabled={(this.state.warnings.length > 0)}
                                                        data-attrid={451}
                                                        data-attrtype={this.props.attrtype}
                                                        data-datatype={this.props.datatype}
                                                        data-datalength={3} />
                                                }

                                            </Table.Cell>
                                            <Table.Cell>
                                                {(index === this.state.decodedColor.colorSets.length - 1) ?
                                                    <Icon link name='minus' onClick={() => this.removeRow()} /> : ''
                                                }
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                })}
                                <Table.Row>
                                    <Table.Cell>
                                        {this.state.decodedColor.colorSets.length < 20 ?
                                            <Icon link name='add' onClick={(event) => this.addRow()} /> : ''
                                        }
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </div>
                    <ClearSubmitComponent parentProps={this.props}
                        warnings={this.state.warnings}
                        submit={this.submit}
                        submitWithoutValue={this.state.props.onSubmitAndClose} />
                </Form>
            </div>
        );
    }

    submit(event) {
        event.target.userInput = { value: encodeColor(this.state.decodedColor) };
        this.state.props.onSubmitAndClose(event);
    }

    submitTuning(index, event) {
        let colorSet = this.state.decodedColor.colorSets[index];
        let encoded = encodeColorSet(colorSet);
        event.target.userInput = { value: encoded.substring(6) };
        this.state.props.onSubmit(event);
    }

    removeRow() {
        let removedRow = this.state.decodedColor;
        removedRow.colorSets.pop();
        this.setState({ decodedColor: removedRow })
    }

    addRow(index = 0) {
        let addedRow;
        if (index) {
            for (let i = 0; i < index; i++) {
                if (this.state.decodedColor.colorSets.length < index && this.state.colorDefaults[this.state.decodedColor.colorSets.length]) {
                    addedRow = this.addDefaultRow(this.state.decodedColor.colorSets.length);
                }
            }
            this.setState({ decodedColor: addedRow })
            return;
        }
        if (this.state.colorDefaults[this.state.decodedColor.colorSets.length]) {
            addedRow = this.addDefaultRow(this.state.decodedColor.colorSets.length);
        } else {
            addedRow = this.addCustomRow();
        }
        this.setState({ decodedColor: addedRow })
    }

    addCustomRow() {
        let addedRow = this.state.decodedColor;
        addedRow.colorSets.push({
            hex: '000000',
            definition: { red: 0, green: 0, blue: 0 },
            tuning: { red: 0, green: 0, blue: 0 }
        });
        return addedRow;
    }

    addDefaultRow(index) {
        let addedRow = this.state.decodedColor;
        let defaultRow = this.state.colorDefaults[index];
        let colorSet = {
            definition: { red: defaultRow.red, green: defaultRow.green, blue: defaultRow.blue },
            tuning: { red: defaultRow.red, green: defaultRow.green, blue: defaultRow.blue }
        };
        encodeColorSet(colorSet);
        addedRow.colorSets.push(colorSet);
        return addedRow;
    }

    calculatePercentage(currentColorValue) {
        let percentage = ((Number(currentColorValue) / 255) * 100);
        return `${percentage.toFixed(2)} %`;
    }

    validateInput(event, param = "", percentageKey, colorKey) {
        let target = event.target;
        let dataSet = target.parentElement.dataset;
        let inputValue = event.target.value;
        if (inputValue > 255 || inputValue < 0 || isNaN(inputValue) || inputValue.includes('.')) {
            target.classList.add('error');
            this.addWarning(dataSet.column, dataSet.row)
        } else {
            let colorSet = this.setInput(dataSet.column, dataSet.row, inputValue)
            event.target.parentElement.parentElement.parentElement.parentElement.querySelector('.w-color-swatch').firstChild.style.backgroundColor = '#' + colorSet.hex
            target.classList.remove('error');
            this.removeWarning(dataSet.column, dataSet.row);
            if (param) {
                const tuningKeyMap = {
                    "redPercentage": "redPercentage",
                    "greenPercentage": "greenPercentage",
                    "bluePercentage": "bluePercentage",
                    "red": "red",
                    "blue": "blue",
                    "green": "green",
                };
                if (tuningKeyMap[percentageKey]) {
                    param["tuning"][tuningKeyMap[percentageKey]] = this.calculatePercentage(inputValue);
                    param["tuning"][tuningKeyMap[colorKey]] = inputValue.toString();
                }
            }
        }
    }

    setInput(column, row, input) {
        let newValue = JSON.parse(JSON.stringify(this.state.decodedColor));
        let changedColor;
        if (column.includes('tuning')) {
            changedColor = newValue.colorSets[row].tuning;
        } else {
            changedColor = newValue.colorSets[row].definition;
        }
        if (column.includes('red')) {
            changedColor.red = input;
        } else if (column.includes('green')) {
            changedColor.green = input;
        } else if (column.includes('blue')) {
            changedColor.blue = input;
        }
        encodeColorSet(newValue.colorSets[row])
        return newValue.colorSets[row];
    }

    editColors(event, index, disableColorsTo) {
        if (index >= disableColorsTo) {
            let target = event.target;
            let dataSet = target.parentElement.dataset;
            let inputValue = event.target.value;
            let colorSet = this.setInput(dataSet.column, dataSet.row, Number(inputValue));
            let decodedColor = this.state.decodedColor;
            decodedColor.colorSets[dataSet.row] = colorSet
            this.setState({ decodedColor: decodedColor });
        }
    }

    addWarning(column, row) {
        let warnings = this.state.warnings;
        let createdWarning = 'Error in row ' + (Number(row) + 1) + ' column ' + column;
        this.setState({ warnings: warnings })
        if (!warnings.includes(createdWarning)) {
            warnings.push(createdWarning)
            this.setState({ warnings: warnings })
        }
    }

    removeWarning(column, row) {
        let createdWarning = 'Error in row ' + (Number(row) + 1) + ' column ' + column;
        let warnings = this.state.warnings.filter(warning => warning !== createdWarning);
        this.setState({ warnings: warnings })
    }
}
