import {
    decodeLimitedDecimal,
} from "../decode_bulb_attributes";
import ConfigDecode from "./ConfigDecode";
import ConfigAttribute, {EncodeTypes} from "./ConfigAttribute";
import {cloneDeep} from "lodash";
import {pages501} from "./WizardPages";
import {whenVersionIsChosen} from "./Config501Rules";
import {cctValues} from "./Config500Obj";
let versions = [{key:'1', value: '1'},{key:'2', value: '2'},{key:'3', value: '3'}];
let cctOptions = cloneDeep(cctValues);
let versionAttribute = new ConfigAttribute(0, 'Version', EncodeTypes.LimitedSelection, 'Version of the config attribute', versions);
let colorTemp = new ConfigAttribute(2, 'Color Temp', EncodeTypes.LimitedSelection, 'Color temperature value', cctOptions, 2);

const component501V1 = [
    versionAttribute, colorTemp,
    new ConfigAttribute(3, 'PWM Percent', EncodeTypes.Percent, 'Color Temp with PWM percent. 0 is warm white and 100 is cool white.'),
];
const component501V2 = [
    versionAttribute, colorTemp,
    new ConfigAttribute(3, 'Warm White PWM Percent', EncodeTypes.Percent, 'The percent of the warm white LED PWM'),
    new ConfigAttribute(4, 'Cool White PWM Percent', EncodeTypes.Percent, 'The percent of the warm white LED PWM'),
];
const component501V3 = [
    versionAttribute, colorTemp,
    new ConfigAttribute(3, 'Color Temp PWM Percent', EncodeTypes.GPIO, '0 is warm white and 100 is cool white'),
    new ConfigAttribute(4, 'Brightness PWM Percent', EncodeTypes.GPIO, 'The percent for the brightness PWM.  Normally brightness is only affected by brightness changes but this setting lets you limit the peak brightness for a given colour temperature.')
];

class Config501 extends ConfigDecode {
    constructor(configValue) {
        super(configValue, component501(configValue));
        this.minColorTemp = '2200';
        this.maxColorTemp = '6500';
        this.midColorTemp = '3500';
        this.cctPossibleValues = cctOptions;
        this.setOptions = () => {
            this.cctPossibleValues.forEach((option) => {
                option.hidden = option.value < this.minColorTemp || option.value > this.maxColorTemp;
            });
        }

        this.buildWizardPages();
    }

    resetConfigValue(newConfigAttr) {
        this._setBaseValues(newConfigAttr, component501(newConfigAttr))
        this.decode();
    }

    buildWizardPages() {
        this.pagesConfig = [{
            id: 'min',
            readableValue: this.minColorTemp,
            editable: true,
            possibleValues: cctValues,
            visible: true,
            setReadableValue(value) {
                this.readableValue = value;
                this.setMinColorTemp(value)
                this.setOptions()
            },
            setMinColorTemp: (value) => {
                this.minColorTemp = value;
            },
            setOptions: this.setOptions
        }, {
            id: 'max',
            readableValue: this.maxColorTemp,
            editable: true,
            possibleValues: cctValues,
            visible: true,
            setReadableValue(value) {
                this.readableValue = value;
                this.setMaxColorTemp(value)
                this.setOptions()
            },
            setMaxColorTemp: (value) => {
                this.maxColorTemp = value;
            },
            setOptions: this.setOptions
        }, {
            id: 'mid',
            readableValue: this.midColorTemp,
            editable: true,
            possibleValues: cctValues,
            visible: false,
            setReadableValue(value) {
                this.readableValue = value;
                this.setMidColorTemp(value)
            },
            setMidColorTemp: (value) => {
                this.midColorTemp = value;
            },
        }];

        this.setOptions();
        this.wizardPages = pages501(this.pagesConfig);
    }

    decode() {
        let decodedAttributes = {
            version: this._buildConfigAttributeValues(this.attributes[0], 0),
            headings: [],
            groups: []
        };

        for(let configIndex = 1; configIndex < this.attributes.length; configIndex++){
            decodedAttributes.headings.push(this.attributes[configIndex].attribute)
        }
        let nextId = 2;
        let numAttributesInGroup = this.attributes.length-1;
        for (let configArrayIndex = 1; configArrayIndex < this.splitConfigValue.length; configArrayIndex) {
            decodedAttributes.groups.push({attributes:[]})
            for (let groupIndex = 0; groupIndex < numAttributesInGroup; groupIndex++) {
                let builtAttr = this._buildConfigAttributeValues(this.attributes[groupIndex+1], configArrayIndex);
                builtAttr.id = nextId;
                nextId++;
                if (builtAttr.numOfAttributesFromComponent > 1) {
                    configArrayIndex++;
                }
                decodedAttributes.groups[decodedAttributes.groups.length-1].attributes.push(builtAttr);
                configArrayIndex++;
            }
        }
        this.decodedAttributes = decodedAttributes;
        this.setRules();
        return decodedAttributes;
    }

    encode() {
        let flatAttributes = this.#flattenConfigAttributes();
        let encodedValue='';
        flatAttributes.forEach(attr => {
            if (attr.rawValue !== undefined){
                encodedValue += String(attr.rawValue).replace(/,/g,'');
            }
        })
        this._setBaseValues(encodedValue, component501(encodedValue));
    }

    addGroup() {
        let nextId = this.#flattenConfigAttributes().length+1;
        let newGroup = {attributes:[]};
        for (let groupIndex = 1; groupIndex < this.attributes.length; groupIndex++) {
            let builtAttr = cloneDeep(this.attributes[groupIndex]);
            builtAttr.id = nextId;
            if (builtAttr.attribute === 'Color Temp') {
                builtAttr.setReadableValue(this.nextAvailableCCT());
            } else {
                builtAttr.setRawValue('00');
            }
            nextId++;
            newGroup.attributes.push(builtAttr);
        }
        this.decodedAttributes.groups.push(newGroup);
    }

    nextAvailableCCT() {
        let usedCCTs = this.decodedAttributes.groups.map(group => group.attributes[0].readableValue);
        let cct = this.cctPossibleValues.find(cct => !cct.hidden && !usedCCTs.includes(cct.key));
        return (cct)?cct.key:false;
    }

    removeGroup(index) {
        if (index) {
            this.decodedAttributes.groups.splice(index, 1);
            return;
        }
        this.decodedAttributes.groups.pop();
    }

    #flattenConfigAttributes() {
        let flattened=[this.decodedAttributes.version];
        this.decodedAttributes.groups.forEach(group => {
            flattened.push(...group.attributes);
        })
        return flattened;
    }

    findAttribute(attrId) {
        let pageAttr = this.pagesConfig.find(attr => attr.id === attrId);
        if (pageAttr) {
            return pageAttr;
        }
        return this.#flattenConfigAttributes().find(attr => attr.id === attrId);
    }

    setRules() {
        let version = this.decodedAttributes.version;
        this.bindRule(whenVersionIsChosen, version,[this.wizardPages]);
    }

    bindRule(rule, me, attributes) {
        rule = rule.bind(this, me, ...attributes)
        me.addRule(rule);
    }

    runRules() {
        this.decodedAttributes.version.runRules();
    }

    sort() {
        this.decodedAttributes.groups.sort((groupA, groupB) => {
            if (Number(groupA.attributes[0].readableValue) < Number(groupB.attributes[0].readableValue)) {
                return -1;
            }
            if (Number(groupA.attributes[0].readableValue) > Number(groupB.attributes[0].readableValue)) {
                return 1;
            }
            return 0;
        })
    }
}

function component501(configValue) {
    let version = decodeLimitedDecimal(configValue.substring(0,2), versions)
    switch (version) {
        case '3':
            return component501V3;
        case '2':
            return component501V2;
        default:
            return component501V1;
    }
}

export default Config501
