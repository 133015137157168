import React from 'react';
import ReactTooltip from 'react-tooltip';
import { Icon } from 'semantic-ui-react';

export default function HeaderAccountComponent({ handle_routeAccountDetails, handle_routeHome, handle_routeSignOut }) {
  return (
    <div style={{ background: '#454b4e' }} className='headerAccount-container'>
      <div id='account-homeRoute-item' onClick={handle_routeHome}>
        <Icon data-tip data-for='homeTip' size='large' name='home' />
        <ReactTooltip id='homeTip' type='light' effect='solid'>
          <span>Home</span>
        </ReactTooltip>
      </div>

      <div id='account-accountRoute-item' onClick={handle_routeAccountDetails}>
        <Icon data-tip data-for='userTip' size='large' name='user' />
        <ReactTooltip id='userTip' type='light' effect='solid'>
          <span>Account info</span>
        </ReactTooltip>
      </div>

      <div id='account-guideRoute-item'>
        <a href='https://developer.afero.io/Inspector' target='_blank' rel='noopener noreferrer'>
          <Icon data-tip data-for='GuideTip' size='large' name='question' />
        </a>
        <ReactTooltip id='GuideTip' type='light' effect='solid'>
          <span>User Guide</span>
        </ReactTooltip>
      </div>

      <div id='account-signoutRoute-item' onClick={handle_routeSignOut}>
        <Icon data-tip data-for='signoutTip' size='large' name='sign out' />
        <ReactTooltip id='signoutTip' type='light' effect='solid'>
          <span>Sign out</span>
        </ReactTooltip>
      </div>
    </div>
  );
}
