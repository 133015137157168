/*allow user to sort both device and afero's attributes on MainComponent*/

export default function sortAttributesProcess(column, sortArrow, attrType) {
  return (dispatch, getState) => {
    //sorting device attributes
    switch (attrType) {
      case 'deviceAttr':
        let devAttrUpdated = Object.assign({}, getState().deviceAttribute);

        if (column === 'attrId' && sortArrow === 'up') {
          devAttrUpdated.attributes.sort((a, b) => {
            return a.attributeId - b.attributeId;
          });
        } else if (column === 'attrId' && sortArrow === 'down') {
          devAttrUpdated.attributes.sort((a, b) => {
            return b.attributeId - a.attributeId;
          });
        } else if (column === 'attrName' && sortArrow === 'up') {
          devAttrUpdated.attributes.sort((a, b) => {
            if (a.semanticType.toLowerCase() < b.semanticType.toLowerCase()) {
              return -1;
            }
            return true;
          });
        } else if (column === 'attrName' && sortArrow === 'down') {
          devAttrUpdated.attributes.sort().reverse();
        } else if (column === 'attrDate' && sortArrow === 'up') {
          devAttrUpdated.attributes.sort((a, b) => {
            return a.updatedTimestamp - b.updatedTimestamp;
          });
        } else if (column === 'attrDate' && sortArrow === 'down') {
          devAttrUpdated.attributes.sort((a, b) => {
            return b.updatedTimestamp - a.updatedTimestamp;
          });
        }

        dispatch({ type: 'SORT_DEVICE_ATTRIBUTES', deviceAttribute: devAttrUpdated });
        break;

      //sorting afero attributes
      case 'aferoAttr':
        let aferoAttrUpdated = Object.assign({}, getState().aferoAttribute);

        if (column === 'attrId' && sortArrow === 'up') {
          aferoAttrUpdated.attributes.sort((a, b) => {
            return a.attributeId - b.attributeId;
          });
        } else if (column === 'attrId' && sortArrow === 'down') {
          aferoAttrUpdated.attributes.sort((a, b) => {
            return b.attributeId - a.attributeId;
          });
        } else if (column === 'attrName' && sortArrow === 'up') {
          aferoAttrUpdated.attributes.sort((a, b) => {
            if (a.semanticType.toLowerCase() < b.semanticType.toLowerCase()) {
              return -1;
            }
            return true;
          });
        } else if (column === 'attrName' && sortArrow === 'down') {
          aferoAttrUpdated.attributes.sort().reverse();
        } else if (column === 'attrDate' && sortArrow === 'up') {
          aferoAttrUpdated.attributes.sort((a, b) => {
            return a.updatedTimestamp - b.updatedTimestamp;
          });
        } else if (column === 'attrDate' && sortArrow === 'down') {
          aferoAttrUpdated.attributes.sort((a, b) => {
            return b.updatedTimestamp - a.updatedTimestamp;
          });
        }

        dispatch({ type: 'SORT_AFERO_ATTRIBUTES', aferoAttribute: aferoAttrUpdated });
        break;
      default:
        return false;
    }
  };
}
