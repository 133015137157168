import { AFERO_DATATYPES, PRIVATE_COMMUNICATION_CHANNEL, PRIVATE_SYSTEM } from '../../util/constants';
import { getDeviceProfile } from "../../api/http_request_wrapper";
import {parseDataForDeviceDetailsByAccountId} from "./getDevAttributesProcess";

/*this middleware parses for Afero system attributes based on
deviceId selected by user on either HomeComponent or MainComponent*/

export default function getAferoAttributesProcess(router, deviceProfile) {
  let aferoSystemScope = {};

  return async (dispatch, getState) => {
    try {
      /* parsing data for Devices details by accountId */
      let {curAttrValue, devDetails} = parseDataForDeviceDetailsByAccountId();
      let aferoAttributeScope = curAttrValue;
      /* parsing data for Devices's profile by accountId & deviceId */
      let devProfile = deviceProfile;
      if (!deviceProfile){
        devProfile = await getDeviceProfile(devDetails[0].accountId, devDetails[0].deviceId)
      }
      let devAttrParsed = devProfile.services.map(prop => {
        return prop.attributes.map(attr => {
          let obj = {};

          obj.available = devDetails[0].available;
          obj.attributeId = attr.id;
          obj.value = aferoAttributeScope[devDetails[0].deviceId][attr.id].value;
          obj.dataType = attr.dataType;
          obj.length = attr.length;
          obj.updatedTimestamp = aferoAttributeScope[devDetails[0].deviceId][attr.id].updatedTimestamp;
          obj.semanticType = attr.semanticType;
          obj.updatedErrorMsg = '';
          obj.operations = attr.operations;
          return obj;
        });
      });
      devDetails[0].attributes = devAttrParsed; //add new property, attributes, to devDetails

      aferoSystemScope.aferoDetails = devDetails.map(devProps => {
        let attrFiltered = devProps.attributes[0].filter(prop => {
          return (
            (prop.attributeId >= AFERO_DATATYPES.MCU_VERSION.min && prop.attributeId <= AFERO_DATATYPES.MCU_VERSION.max) ||
            (prop.attributeId >= AFERO_DATATYPES.VERSION.min && prop.attributeId <= AFERO_DATATYPES.VERSION.max) ||
            (prop.attributeId >= AFERO_DATATYPES.COMMUNICATION_CHANNEL.min &&
              prop.attributeId <= AFERO_DATATYPES.COMMUNICATION_CHANNEL.max &&
              !PRIVATE_COMMUNICATION_CHANNEL.includes(prop.attributeId)) ||
            (prop.attributeId >= AFERO_DATATYPES.SYSTEM.min &&
              prop.attributeId <= AFERO_DATATYPES.SYSTEM.max &&
              !PRIVATE_SYSTEM.includes(prop.attributeId))
          );
        });
        return {
          accountId: devProps.accountId,
          friendlyName: devProps.friendlyName,
          available: devProps.available,
          deviceId: devProps.deviceId,
          profileId: devProps.profileId,
          partnerId: devProps.partnerId,
          deviceTypeId: devProps.deviceTypeId,
          latitude: devProps.latitude,
          longitude: devProps.longitude,
          updatedTimestamp: devProps.updatedTimestamp,
          attributes: attrFiltered
        };
      });

      dispatch({
        type: 'GET_AFERO_ATTRIBUTES',
        aferoAttribute: aferoSystemScope.aferoDetails[0],
        getAferoAttributesFlag: true
      });
    } catch (error) {
      console.log(error);
    }
  };
}
