import React, { useState } from 'react';
import { Button, Table, Popup } from 'semantic-ui-react';
import ReactTooltip from 'react-tooltip';
import ConfirmationComponent from './custom/confirmationPopUpComponent'

export default function DeviceInforComponent({
  deviceAttribute,
  handle_downloadDeviceProfile,
  handle_updateDeviceAttribute
}) {


  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleForceFactoryPopup = () => {
    setShowConfirmation(true);
  };

  const handleConfirmation = () => {
    setShowConfirmation(false);
  };

  return (
    <div className='device-info-container'>
      <div id='deviceInfoContent'>
        <Table unstackable singleLine compact='very' basic='very'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan='4'>
                <strong className='device-info-title'>Device Information</strong>{' '}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell collapsing>Device ID:</Table.Cell>
              <Table.Cell>{deviceAttribute.deviceId}</Table.Cell>
              <Table.Cell collapsing>Device Type ID:</Table.Cell>
              <Table.Cell>{deviceAttribute.deviceTypeId}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell collapsing>Friendly Name:</Table.Cell>
              <Table.Cell>
                <div>
                  {deviceAttribute.friendlyName}
                </div>
              </Table.Cell>

              <Table.Cell collapsing>Partner ID:</Table.Cell>
              <Table.Cell>{deviceAttribute.partnerId}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell collapsing>Profile ID:</Table.Cell>
              <Table.Cell>
                {deviceAttribute.profileId}{' '}
                <Button
                  circular
                  size='mini'
                  icon='download'
                  data-tip
                  data-for='deviceProfileTip'
                  onClick={handle_downloadDeviceProfile}
                />
                <ReactTooltip id='deviceProfileTip' type='light' effect='solid' place='right'>
                  <span>Download device's profile</span>
                </ReactTooltip>
              </Table.Cell>

              <Table.Cell collapsing>Latitude/Longitude:</Table.Cell>
              <Table.Cell>
                {!deviceAttribute.latitude || !deviceAttribute.longitude ? (
                  '-'
                ) : (
                  deviceAttribute.latitude + ' , ' + deviceAttribute.longitude
                )}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell collapsing>Force Factory Test Mode ON:</Table.Cell>
              <Table.Cell>
                <Popup
                  trigger={
                    <Button
                      circular
                      size='mini'
                      icon='factory'
                      data-for='deviceProfileTips'
                      onClick={handleForceFactoryPopup}
                    />
                  }
                  on='click'
                  open={showConfirmation}
                  position='right center'
                />
                {
                  <ConfirmationComponent
                    open={showConfirmation}
                    onClose={() => setShowConfirmation(false)}
                    onConfirm={handleConfirmation}
                    handle_updateDeviceAttribute={handle_updateDeviceAttribute}
                  />
                }
                <ReactTooltip id='deviceProfileTips' type='light' effect='solid' place='right'>
                  <span>Enable FTM Mode</span>
                </ReactTooltip>
              </Table.Cell>

            </Table.Row>
          </Table.Body>
        </Table>
      </div>
    </div>
  );
}
