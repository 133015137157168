import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import { Icon } from 'semantic-ui-react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import FooterComponent from '../main/FooterComponent';
import HeaderHomeComponent from './HeaderHomeComponent';
import LoaderComponent from '../miscellaneous/LoaderComponent';
import keycloak from "../../keycloak";

export default class HomeComponent extends Component {
  /*store deviceId to localStorage and toggle rssiGraph*/
  handle_selectDeviceId = event => {
    event.preventDefault();
    let { router } = this.props;

    let obj = {
      deviceId: event.currentTarget.dataset.deviceid,
      friendlyName: event.currentTarget.dataset.friendlyname,
      direct: event.currentTarget.dataset.direct
    };

    //user's selected deviceId will be stored in localStorage
    localStorage.setItem('selectedDeviceObj', JSON.stringify(obj));
    router.navigate(`/main/${obj.deviceId}`);
    //history.push({ pathname: `/main`, search: `?deviceID=${obj.deviceId}` }); //set URL param
  };

  /*reroute to Account Component*/
  handle_routeAccount = event => {
    event.preventDefault();
    let { flag_displayCurrentConnection, router } = this.props;

    flag_displayCurrentConnection(false);
    router.navigate(`/account`);
  };

  /*reroute to Home Component*/
  handle_routeHome = event => {
    event.preventDefault();
    let { flag_displayCurrentConnection, router } = this.props;

    flag_displayCurrentConnection(false);
    router.navigate(`/home`);
  };

  /*reroute to Signout Component*/
  handle_routeSignOut = event => {
    event.preventDefault();
    let { clear_rootReducer, router } = this.props;

    clear_rootReducer();
    router.navigate(`/`);
    keycloak.logout()
  };

  render() {
    let { getUserStateFlag, userState } = this.props;
    return (
      <div>
        {!getUserStateFlag ? (
          <LoaderComponent />
        ) : (
          <div className='home-container'>
            <div id='headerRow-item'>
              <HeaderHomeComponent
                handle_routeAccount={this.handle_routeAccount}
                handle_routeHome={this.handle_routeHome}
                handle_routeSignOut={this.handle_routeSignOut}
              />
            </div>
            <div id='leftCol-item' />
            <div id='midCol-item'>
              <div className='homeWelcome-container'>
                <div id='deviceInfo-item'>
                  <div>
                    <h1>Welcome to Afero Inspector</h1>
                    <p id='tallyActiveDevice'>
                      Total Devices: {userState.devActiveStatus.totalDev} - Active Devices: {userState.devActiveStatus.devOnline}
                    </p>
                  </div>
                </div>
              </div>
              <div className='homeDevices-container'>
                {userState.devDetails
                  .sort((a, b) => b.available - a.available) //sort by Online devices
                  .map((dev, i) => {
                    return (
                      <div
                        key={i}
                        className='deviceCard-container'
                        data-deviceid={dev.deviceId}
                        data-friendlyname={dev.friendlyName}
                        data-direct={dev.direct}
                      >
                        <div
                          id='deviceImage-item'
                          onClick={this.handle_selectDeviceId}
                          data-deviceid={dev.deviceId}
                          data-friendlyname={dev.friendlyName}
                          data-direct={dev.direct}
                        >
                          <img
                            src={dev.deviceIconUrl}
                            alt='deviceImg'
                            data-deviceid={dev.deviceId}
                            data-friendlyname={dev.friendlyName}
                            data-direct={dev.direct}
                          />
                        </div>

                        <div
                          id='deviceDetails-item'
                          data-deviceid={dev.deviceId}
                          data-friendlyname={dev.friendlyName}
                          data-direct={dev.direct}
                        >
                          <p
                            data-tip
                            data-for={'deviceFriendlyTip' + i}
                            className='deviceFriendlyName'
                            data-deviceid={dev.deviceId}
                            data-friendlyname={dev.friendlyName}
                            data-direct={dev.direct}
                            onClick={this.handle_selectDeviceId}
                          >
                            {dev.friendlyName}
                          </p>

                          <CopyToClipboard text={dev.deviceId}>
                            <p
                              data-tip
                              data-for={'deviceIdTip' + i}
                              className='deviceId'
                              data-deviceid={dev.deviceId}
                              data-friendlyname={dev.friendlyName}
                              data-direct={dev.direct}
                            >
                              {dev.deviceId}
                            </p>
                          </CopyToClipboard>

                          <ReactTooltip id='clipboard' type='light' effect='solid' place='bottom'>
                            <span>Copy</span>
                          </ReactTooltip>

                          <ReactTooltip id={'deviceIdTip' + i} type='light' effect='solid' place='bottom'>
                            <span>Click to copy Device ID</span>
                          </ReactTooltip>

                          {dev.friendlyName.length > 23 ? (
                            <ReactTooltip id={'deviceFriendlyTip' + i} type='light' effect='solid' place='bottom'>
                              <span>{dev.friendlyName}</span>
                            </ReactTooltip>
                          ) : (
                            ''
                          )}
                        </div>

                        <div
                          id='deviceOnline-item'
                          data-deviceid={dev.deviceId}
                          data-friendlyname={dev.friendlyName}
                          onClick={this.handle_selectDeviceId}
                        >
                          {dev.available === false ? (
                            <div
                              className='utilityInfo'
                              data-deviceid={dev.deviceId}
                              data-friendlyname={dev.friendlyName}
                              data-direct={dev.direct}
                            >
                              <Icon inverted color='grey' name='circle' />
                              Offline
                            </div>
                          ) : (
                            <div
                              className='utilityInfo'
                              data-deviceid={dev.deviceId}
                              data-friendlyname={dev.friendlyName}
                              data-direct={dev.direct}
                            >
                              <Icon inverted style={{ color: '#ffac3e' }} name='circle' />
                              Online
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div id='rightCol-item' />
            <div id='footerRow-item'>
              <FooterComponent />
            </div>
          </div>
        )}
      </div>
    );
  }
}
