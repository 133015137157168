export default function rootReducer(
  currentState = {
    aferoAttribute: {},
    connectionLogs: {},
    deviceAttribute: {},
    deviceProfile: {},
    deviceLogs: {},
    getAferoAttributesFlag: false,
    getDeviceAttributesFlag: false,
    getUserStateFlag: false,
    repullApiFlag: false,
    signinErrorMsg: '',
    displayCurrentConnectionFlag: false,
    updateAferoAttributeFlag: false,
    updateDeviceAttributeFlag: false,
    userState: {}
  },
  action
) {
  const { deviceId } = JSON.parse(localStorage.getItem('selectedDeviceObj'))
    ? JSON.parse(localStorage.getItem('selectedDeviceObj'))
    : '';
  switch (action.type) {
    case 'CLEAR_ROOT_REDUCER':
      return {
        ...currentState,
        aferoAttribute: action.aferoAttribute,
        connectionLogs: action.connectionLogs,
        deviceAttribute: action.deviceAttribute,
        deviceProfile: action.deviceProfile,
        deviceLogs: action.deviceLogs,
        getAferoAttributesFlag: action.getAferoAttributesFlag,
        getDeviceAttributesFlag: action.getDeviceAttributesFlag,
        getUserStateFlag: action.getUserStateFlag,
        repullApiFlag: action.repullApiFlag,
        signinErrorMsg: action.signinErrorMsg,
        displayCurrentConnectionFlag: action.displayCurrentConnectionFlag,
        updateAferoAttributeFlag: action.updateAferoAttributeFlag,
        updateDeviceAttributeFlag: action.updateDeviceAttributeFlag,
        userState: action.userState
      };

    case 'CLEAR_DEVICE_LOGS':
      return { ...currentState, deviceLogs: action.deviceLogs };

    case 'DEFAULT_CONNECTION_LOGS':
      return { ...currentState, connectionLogs: action.connectionLogs };

    case 'DEFAULT_DEVICE_LOGS':
      let devLogsDefault = action.deviceLogs;
      return { ...currentState, deviceLogs: devLogsDefault };

    case 'FLAG_GET_DEVICE_ATTRIBUTES':
      return { ...currentState, getDeviceAttributesFlag: action.getDeviceAttributesFlag };

    case 'FLAG_DISPLAY_CURRENT_CONNECTION':
      return { ...currentState, displayCurrentConnectionFlag: action.displayCurrentConnectionFlag };

    case 'FLAG_GET_AFERO_ATTRIBUTES':
      return { ...currentState, getAferoAttributesFlag: action.getAferoAttributesFlag };

    case 'FLAG_GET_USER_STATE':
      return { ...currentState, getUserStateFlag: action.getUserStateFlag };

    case 'FLAG_SUCCESSFULLY_UPDATE_DEVICE_ATTRIBUTE':
      return { ...currentState, updateDeviceAttributeFlag: action.updateDeviceAttributeFlag };

    case 'FLAG_SUCCESSFULLY_UPDATE_AFERO_ATTRIBUTE':
      return { ...currentState, updateAferoAttributeFlag: action.updateAferoAttributeFlag };

    case 'GET_DEVICE_ATTRIBUTES':
      return { ...currentState, deviceAttribute: action.deviceAttribute, getDeviceAttributesFlag: action.getDeviceAttributesFlag };

    case 'GET_AFERO_ATTRIBUTES':
      return { ...currentState, aferoAttribute: action.aferoAttribute, getAferoAttributesFlag: action.getAferoAttributesFlag };

    case 'GET_DEVICE_PROFILE':
      return { ...currentState, deviceProfile: action.deviceProfile };

    case 'GET_USER_DEVICES':
      return { ...currentState, userDevices: action.userDevices };

    case 'GET_USER_STATE':
      return { ...currentState, userState: action.userState, getUserStateFlag: action.getUserStateFlag };

    case 'SIGNIN_ERROR_MSG':
      return { ...currentState, signinErrorMsg: action.signinErrorMsg };

    case 'ON_INVALIDATE':
      return { ...currentState, repullApiFlag: action.repullApiFlag };

    case 'SORT_AFERO_ATTRIBUTES':
      return { ...currentState, aferoAttribute: action.aferoAttribute };

    case 'SORT_DEVICE_ATTRIBUTES':
      return { ...currentState, deviceAttribute: action.deviceAttribute };

    case 'UPDATE_USER_STATE':
      return { ...currentState, userState: action.userState };

    case 'UPDATE_DEVICE_ATTRIBUTES':
      return { ...currentState, deviceAttribute: action.deviceAttribute };

    case 'UPDATE_AFERO_ATTRIBUTES':
      return { ...currentState, aferoAttribute: action.aferoAttribute };

    case 'UPDATE_BLUETOOTH_LOGS':
      let connectionLogsBluetoothUpdated = Object.assign({}, currentState.connectionLogs);
      //console.log('BLUE CONNECTED***', action.connectionLogs);
      connectionLogsBluetoothUpdated[deviceId].connections.bluetoothSignal = action.connectionLogs;
      connectionLogsBluetoothUpdated[deviceId].connections.activeConnection = 'bluetooth';
      return { ...currentState, connectionLogs: connectionLogsBluetoothUpdated };

    case 'UPDATE_WIFI_LOGS':
      let connectionLogsWifiUpdated = Object.assign({}, currentState.connectionLogs);
      //console.log('WIFI CONNECTED***', action.connectionLogs);
      connectionLogsWifiUpdated[deviceId].connections.wifiSignal = action.connectionLogs;
      connectionLogsWifiUpdated[deviceId].connections.activeConnection = 'wifi';
      return { ...currentState, connectionLogs: connectionLogsWifiUpdated };

    case Action.UPDATE_DEVICE_LOGS:
      let deviceLogsUpdated = Object.assign({}, currentState.deviceLogs);

      deviceLogsUpdated[action.deviceId].logs.unshift(action.deviceLogs);
      return { ...currentState, deviceLogs: deviceLogsUpdated };

    default:
      return currentState;
  }
}

export const Action = {
  UPDATE_DEVICE_LOGS: 'UPDATE_DEVICE_LOGS'
};
