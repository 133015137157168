/*to default Device and Afero Attribute property, updatedErrorMsg back to empty string*/

export default function defaultUpdatedErrorMsgProcess(attrType, attrId) {
  return (dispatch, getState) => {
    switch (attrType) {
      case 'deviceAttribute':
        let curDeviceAttribute = getState().deviceAttribute;

        curDeviceAttribute.attributes.some(obj => {
          return obj.attributeId === Number(attrId) ? (obj.updatedErrorMsg = '') : '';
        });

        dispatch({ type: 'UPDATE_DEVICE_ATTRIBUTES', deviceAttribute: curDeviceAttribute });
        dispatch({ type: 'FLAG_SUCCESSFULLY_UPDATE_DEVICE_ATTRIBUTE', updateDeviceAttributeFlag: false });
        break;

      case 'aferoAttribute':
        let curAferoAttribute = getState().aferoAttribute;

        curAferoAttribute.attributes.some(obj => {
          return obj.attributeId === Number(attrId) ? (obj.updatedErrorMsg = '') : '';
        });

        dispatch({ type: 'UPDATE_AFERO_ATTRIBUTES', aferoAttribute: curAferoAttribute });
        dispatch({ type: 'FLAG_SUCCESSFULLY_UPDATE_AFERO_ATTRIBUTE', updateAferoAttributeFlag: false });
        break;

      default:
        return false;
    }
  };
}
