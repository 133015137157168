import { DEFAULT_ICON } from '../../util/constants';
import keycloak from "../../keycloak";
import {getUserDetails} from "../../api/http_request_wrapper";
import * as auth from "../../api/authentication";

/*this middleware will exclude any mobile hubs from device list. It will parse for general
user's devices data based on user credential, partnerAcess, accounts, and deviceDtails*/

export default function getUserStateProcess(router) {
  let userScope = {};
  return async (dispatch, getState) => {
    try {
      let devAccount = JSON.parse(localStorage.getItem('accountDevices'));
      keycloak.userInfo = await keycloak.loadUserProfile();
      /*parse for user details*/
      let userDetails = await getUserDetails();
      userScope.firstName = userDetails.firstName;
      userScope.lastName = userDetails.lastName;
      userScope.userId = userDetails.userId;
      userScope.credentialId = userDetails.credential.credentialId;
      userScope.partnerAccess = userDetails.partnerAccess.map(prop => {
        let obj = {};

        obj.partnerId = prop.partner.partnerId;
        obj.partnerName = prop.partner.name;
        return obj;
      });
      userScope.accounts = userDetails.accountAccess.map(prop => {
        let obj = {};

        obj.accountId = prop.account.accountId;
        obj.description = prop.account.description;
        obj.type = prop.account.type.charAt(0).toUpperCase() + prop.account.type.slice(1).toLowerCase();
        obj.createdTimestamp = prop.account.createdTimestamp;
        obj.owner = prop.privileges.owner;
        return obj;
      });

      /*prase for devDetails*/
      let devDetails = [];
      devAccount.forEach(prop => {
        let obj = {};

        if (prop.profile.presentation) {
          obj.friendlyName = !prop.friendlyName ? 'UNKNOWN' : prop.friendlyName;
          obj.deviceId = prop.deviceId;
          obj.accountId = prop.accountId;
          obj.available = prop.deviceState.available; //show if the device is currently connected
          obj.direct = prop.deviceState.direct; //false = connected to bluetooth, true = connected to wifi
          obj.deviceIconUrl = DEFAULT_ICON; //default deviceIconUrl if null
          prop.profile.presentation.gauge.foreground.images.forEach(img => {
            if (img.imageSize === '1x') {
              obj.deviceIconUrl = img.uri;
              if(img.uri.includes('cdn.dev.afero.io')) {
                obj.deviceIconUrl = img.uri.substring(0, 12) + img.uri.substring(16)
              }
              return true;
            }
            return false;
          });
          devDetails.push(obj);
        }
      });
      userScope.devDetails = devDetails;

      /*identify the total number of devices online*/
      let devActiveObj = {};
      let totalDev = 0;
      let devOnline = 0;

      devAccount.forEach(prop => {
        if (prop.profile.presentation) {
          totalDev++;
          devActiveObj.totalDev = totalDev;
        }
        if (prop.profile.presentation && prop.deviceState.available === true) {
          devOnline++;
          devActiveObj.devOnline = devOnline;
        }
      });
      devActiveObj.offline = totalDev - devOnline;
      userScope.devActiveStatus = devActiveObj;

      let allUserDevs = userScope.devDetails.map(obj => obj.deviceId);
      localStorage.setItem('allUserDevs', JSON.stringify(allUserDevs));

      dispatch({ type: 'GET_USER_STATE', userState: userScope, getUserStateFlag: true });
    } catch (error) {
      console.log(error);
      auth.signOutPromise(dispatch);
      router.navigate(`/`);
    }
  };
}
