import {
    decodeCelsius, decodeDecimalData, decodeFlags,
    decodeFrequency, decodeGPIO, decodeLimitedDecimal,
    decodeMSeconds,
    decodePercent, decodeRGB,
    decodeSeconds
} from "../decode_bulb_attributes";
import {
    encodeAnnotatedUnit,
    encodeDecimalData, encodeFlags,
    encodeGPIO,
    encodeLimitedDecimal, encodeRGB
} from "../encode_bulb_attributes";
import {
    validateCelsius,
    validateDecimalData, validateFrequency, validateGPIO, validateMSeconds,
    validatePercent,
    validateSeconds
} from "../validate_incoming_encode_attribute";
import {cloneDeep} from "lodash";

export const EncodeTypes = {
    Celsius: {decode: decodeCelsius, encode: encodeAnnotatedUnit, validation: validateCelsius},
    Percent: {decode: decodePercent, encode: encodeAnnotatedUnit, validation: validatePercent},
    Seconds: {decode: decodeSeconds, encode: encodeAnnotatedUnit, validation: validateSeconds},
    MSeconds: {decode: decodeMSeconds, encode: encodeAnnotatedUnit, validation: validateMSeconds},
    Frequency: {decode: decodeFrequency, encode: encodeAnnotatedUnit, validation: validateFrequency},
    Decimal: {decode: decodeDecimalData, encode: encodeDecimalData, validation: validateDecimalData},
    GPIO: {decode: decodeGPIO, encode: encodeGPIO, validation: validateGPIO},
    LimitedSelection: {decode: decodeLimitedDecimal, encode: encodeLimitedDecimal},
    Flags: {decode: decodeFlags, encode: encodeFlags},
    RGB: {decode: decodeRGB, encode: encodeRGB}
}

class ConfigAttribute {

    constructor(id, title, encodeType, description, possibleValues = null, bytes = 1) {
        this.title = title;
        this.attribute = title;
        this.description = description;
        this.encodeType = encodeType;
        this.encode = encodeType.encode;
        this.decode = encodeType.decode;
        this.validate = encodeType.validation;
        this.bytes = bytes;
        this.numOfAttributesFromComponent = bytes;
        this.possibleOptions = cloneDeep(possibleValues);
        this.possibleValues = possibleValues;
        this.editable = true;
        this.rules = [];
        this.id = id;
        this.visible = true;
    }

    getPossibleOptions(){
        return cloneDeep(this.possibleOptions);
    }

    changePossibleOptions(newPossibleOptions) {
        this.possibleOptions = cloneDeep(newPossibleOptions);
        this.possibleValues = newPossibleOptions;
    }

    addRule(rule) {
        this.rules.push(rule);
        this.editable = false;
    }

    runRules(param) {
        this.rules.forEach(rule => {
            rule(param);
        });
    }

    setRawValue(value) {
        this.rawValue = value;
        this.readableValue = this.decode(value, this.possibleOptions);
    }

    setReadableValue(value) {
        this.rawValue = this.encode(value, this.numOfAttributesFromComponent, this.possibleOptions);
        this.readableValue = this.decode(this.rawValue.match(/(..?)/g), this.possibleValues);
    }
}

export default ConfigAttribute;
